export type Maybe<T> = T | null | undefined;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Object: any;
};

export interface Activity {
  __typename?: "Activity";
  id: Scalars["ID"];
  reminder_style: ReminderStyle;
  title: I18nText;
  type: Scalars["String"];
  data_input_type?: Maybe<DataInputType>;
  data_input_field?: Maybe<DataInputField>;
  days_between_inputs?: Maybe<Scalars["Int"]>;
}

export interface ActivityMeta {
  __typename?: "ActivityMeta";
  key?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  care_plan?: Maybe<ActivityMetaCarePlan>;
  timeline_event_type?: Maybe<Scalars["String"]>;
}

export interface ActivityMetaCarePlan {
  __typename?: "ActivityMetaCarePlan";
  type?: Maybe<Scalars["String"]>;
}

export interface ActivityPayload {
  id: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
  en?: Maybe<Scalars["String"]>;
  es?: Maybe<Scalars["String"]>;
  reminder_ref?: Maybe<Scalars["String"]>;
  data_input?: Maybe<DataInputInput>;
}

export interface AddObligationsFromCarePlanItemsResult {
  __typename?: "AddObligationsFromCarePlanItemsResult";
  obligations?: Maybe<Array<Maybe<Obligation>>>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface AfsInfo {
  __typename?: "AFSInfo";
  afs_check_date?: Maybe<Scalars["String"]>;
  number_of_weapons_registered?: Maybe<Scalars["Int"]>;
}

export interface AfsInfoInput {
  afs_check_date?: Maybe<Scalars["String"]>;
  number_of_weapons_registered?: Maybe<Scalars["Int"]>;
}

export interface Application {
  __typename?: "Application";
  id: Scalars["ID"];
  timezone?: Maybe<Scalars["String"]>;
  dashboard_buckets?: Maybe<Array<Maybe<DashboardBucket>>>;
  activity_information?: Maybe<Array<Maybe<ActivityMeta>>>;
  risk_levels?: Maybe<Array<Maybe<KeyedString>>>;
  reactivate_reasons?: Maybe<Array<Maybe<KeyedString>>>;
  deactivate_reasons?: Maybe<Array<Maybe<KeyedString>>>;
  assessment_tools?: Maybe<Array<Maybe<AssessmentTool>>>;
  languages?: Maybe<Array<Maybe<KeyedString>>>;
  mobile_app?: Maybe<MobileApp>;
  compliance_options?: Maybe<Array<Maybe<KeyedString>>>;
  disposition_options?: Maybe<Array<Maybe<KeyedString>>>;
  obligation_status_options?: Maybe<Array<Maybe<KeyedString>>>;
  task_config?: Maybe<Array<Maybe<TaskConfig>>>;
  flags?: Maybe<Flags>;
  i18n?: Maybe<I18n>;
  twilio?: Maybe<TwilioConfig>;
}

export interface AssessmentTemplate {
  __typename?: "AssessmentTemplate";
  id: Scalars["ID"];
  assessment_name: Scalars["String"];
  questions?: Maybe<Array<Maybe<AssessmentTemplateQuestion>>>;
  ranges?: Maybe<Array<Maybe<AssessmentTemplateRange>>>;
}

export interface AssessmentTemplateQuestion {
  __typename?: "AssessmentTemplateQuestion";
  id: Scalars["String"];
  question_number: Scalars["Float"];
  question_text: Scalars["String"];
  question_options: Array<Maybe<AssessmentTemplateQuestionOption>>;
}

export interface AssessmentTemplateQuestionInput {
  question_number?: Maybe<Scalars["Float"]>;
  question_text: Scalars["String"];
  question_options: Array<Maybe<AssessmentTemplateQuestionOptionInput>>;
}

export interface AssessmentTemplateQuestionOption {
  __typename?: "AssessmentTemplateQuestionOption";
  value: Scalars["Int"];
  text: Scalars["String"];
}

export interface AssessmentTemplateQuestionOptionInput {
  value: Scalars["Int"];
  text: Scalars["String"];
}

export interface AssessmentTemplateRange {
  __typename?: "AssessmentTemplateRange";
  min_score: Scalars["Int"];
  max_score?: Maybe<Scalars["Int"]>;
  rating: Scalars["String"];
  failure_rate?: Maybe<Scalars["Float"]>;
  failure_to_appear_rate?: Maybe<Scalars["Float"]>;
  new_arrest_rate?: Maybe<Scalars["Float"]>;
}

export interface AssessmentTemplateRangesInput {
  min_score: Scalars["Int"];
  max_score?: Maybe<Scalars["Int"]>;
  rating: Scalars["String"];
  failure_rate?: Maybe<Scalars["Float"]>;
  failure_to_appear_rate?: Maybe<Scalars["Float"]>;
  new_arrest_rate?: Maybe<Scalars["Float"]>;
}

export interface AssessmentTool {
  __typename?: "AssessmentTool";
  id: Scalars["String"];
  name: Scalars["String"];
  scale: Array<Maybe<KeyedString>>;
}

export interface Booking {
  __typename?: "Booking";
  id: Scalars["ID"];
  booking_defendent: BookingDefendent;
  booking_info: BookingInfo;
  booking_charges?: Maybe<Array<BookingCharge>>;
  booking_releases?: Maybe<Array<BookingRelease>>;
  booking_status?: Maybe<BookingStatus>;
  classification: BookingClassification;
  imported_date: Scalars["String"];
  last_updated_at?: Maybe<Scalars["String"]>;
  is_eligible?: Maybe<YesNoUnknown>;
  comments?: Maybe<Scalars["String"]>;
  has_wait?: Maybe<YesNo>;
  spanish_speaker?: Maybe<YesNoUnknown>;
  prospect_status?: Maybe<ProspectStatus>;
  interview_and_assessment?: Maybe<InterviewAndAssessment>;
  is_interview_declined?: Maybe<YesNo>;
  risk_assessment_result?: Maybe<RiskAssessmentResult>;
  recommendation_form?: Maybe<RecommendationForm>;
  recommendation_review_status?: Maybe<RecommendationReviewStatus>;
  court_memo?: Maybe<CourtMemo>;
  pretrial_decision_form?: Maybe<PretrialDecisionForm>;
  participant_id?: Maybe<Scalars["String"]>;
}

export interface BookingCharge {
  __typename?: "BookingCharge";
  id: Scalars["String"];
  booking_charge_sid?: Maybe<Scalars["String"]>;
  booking_sid?: Maybe<Scalars["String"]>;
  booking_number: Scalars["String"];
  booking_datetime?: Maybe<Scalars["String"]>;
  charge_auth_type?: Maybe<Scalars["String"]>;
  charge_code_section: Scalars["String"];
  charge_type?: Maybe<Scalars["String"]>;
  charge_datetime?: Maybe<Scalars["String"]>;
  charge_description?: Maybe<Scalars["String"]>;
  arrest_agency?: Maybe<Scalars["String"]>;
  arrest_datetime?: Maybe<Scalars["String"]>;
  crime_case_number?: Maybe<Scalars["String"]>;
  court_warrant_number?: Maybe<Scalars["String"]>;
}

export interface BookingChargeInput {
  id?: Maybe<Scalars["String"]>;
  booking_charge_sid?: Maybe<Scalars["String"]>;
  booking_sid?: Maybe<Scalars["String"]>;
  booking_number?: Maybe<Scalars["String"]>;
  booking_datetime?: Maybe<Scalars["String"]>;
  charge_auth_type?: Maybe<Scalars["String"]>;
  charge_code_section?: Maybe<Scalars["String"]>;
  charge_type?: Maybe<Scalars["String"]>;
  charge_datetime?: Maybe<Scalars["String"]>;
  charge_description?: Maybe<Scalars["String"]>;
  arrest_agency?: Maybe<Scalars["String"]>;
  arrest_datetime?: Maybe<Scalars["String"]>;
  crime_case_number?: Maybe<Scalars["String"]>;
  court_warrant_number?: Maybe<Scalars["String"]>;
}

export enum BookingClassification {
  NotClassified = "not_classified",
  Conditional = "conditional",
  Referral = "referral",
  Released = "released",
  NewArrest = "new_arrest",
  Supervised = "supervised"
}

export interface BookingDefendent {
  __typename?: "BookingDefendent";
  cii_number?: Maybe<Scalars["String"]>;
  local_id?: Maybe<Scalars["String"]>;
  cdl_id?: Maybe<Scalars["String"]>;
  name: PersonName;
  date_of_birth: Scalars["String"];
  sex?: Maybe<Scalars["String"]>;
  race?: Maybe<Scalars["String"]>;
  booking_number?: Maybe<Scalars["String"]>;
}

export interface BookingDefendentInput {
  cii_number?: Maybe<Scalars["String"]>;
  local_id?: Maybe<Scalars["String"]>;
  cdl_id?: Maybe<Scalars["String"]>;
  name?: Maybe<PersonNameInput>;
  date_of_birth?: Maybe<Scalars["String"]>;
  sex?: Maybe<Scalars["String"]>;
  race?: Maybe<Scalars["String"]>;
  booking_number?: Maybe<Scalars["String"]>;
}

export interface BookingGroup {
  __typename?: "BookingGroup";
  id: Scalars["ID"];
  bookings: Array<Booking>;
  ineligible_bookings?: Maybe<Array<Booking>>;
  created_at: Scalars["String"];
  updated_at?: Maybe<Scalars["String"]>;
  email_sent?: Maybe<Scalars["Boolean"]>;
}

export interface BookingInfo {
  __typename?: "BookingInfo";
  booking_sid?: Maybe<Scalars["String"]>;
  local_id?: Maybe<Scalars["String"]>;
  booking_number?: Maybe<Scalars["String"]>;
  booking_datetime?: Maybe<Scalars["String"]>;
  booking_address?: Maybe<Scalars["String"]>;
  booking_date?: Maybe<Scalars["String"]>;
  arrest_agency?: Maybe<Scalars["String"]>;
  arrested_for?: Maybe<Scalars["String"]>;
}

export interface BookingInfoInput {
  booking_sid?: Maybe<Scalars["String"]>;
  local_id?: Maybe<Scalars["String"]>;
  booking_number?: Maybe<Scalars["String"]>;
  booking_datetime?: Maybe<Scalars["String"]>;
  booking_address?: Maybe<Scalars["String"]>;
}

export interface BookingInfoUpdateDataInput {
  booking_date?: Maybe<Scalars["String"]>;
  arrest_agency?: Maybe<Scalars["String"]>;
  arrested_for?: Maybe<Scalars["String"]>;
  court_appearance_datetime?: Maybe<Scalars["String"]>;
  court_room?: Maybe<Scalars["String"]>;
  court_reason?: Maybe<Scalars["String"]>;
}

export interface BookingPayload {
  id: Scalars["String"];
  booking_defendent: BookingDefendentInput;
  booking_info: BookingInfoInput;
  booking_charges?: Maybe<Array<BookingChargeInput>>;
  booking_releases?: Maybe<Array<BookingReleaseInput>>;
  booking_status?: Maybe<BookingStatusInput>;
  is_eligible?: Maybe<YesNoUnknown>;
}

export interface BookingRelease {
  __typename?: "BookingRelease";
  booking_number?: Maybe<Scalars["String"]>;
  charge_code_section: Scalars["String"];
  charge_description: Scalars["String"];
  charge_type: Scalars["String"];
  charge_release_datetime: Scalars["String"];
  charge_release_type: Scalars["String"];
  warrant_number?: Maybe<Scalars["String"]>;
  court_case_number?: Maybe<Scalars["String"]>;
  jc_control?: Maybe<Scalars["String"]>;
  created_datetime?: Maybe<Scalars["String"]>;
}

export interface BookingReleaseInput {
  booking_number?: Maybe<Scalars["String"]>;
  charge_code_section?: Maybe<Scalars["String"]>;
  charge_description?: Maybe<Scalars["String"]>;
  charge_type?: Maybe<Scalars["String"]>;
  charge_release_datetime?: Maybe<Scalars["String"]>;
  charge_release_type?: Maybe<Scalars["String"]>;
  warrant_number?: Maybe<Scalars["String"]>;
  court_case_number?: Maybe<Scalars["String"]>;
  jc_control?: Maybe<Scalars["String"]>;
  created_datetime?: Maybe<Scalars["String"]>;
}

export enum BookingReleaseType {
  Bail = "bail",
  Pta = "pta",
  Reor = "reor",
  Rncf = "rncf",
  Aowp = "aowp"
}

export interface BookingStatus {
  __typename?: "BookingStatus";
  booking_number: Scalars["String"];
  court_appearance_datetime: Scalars["String"];
  court_reason?: Maybe<Scalars["String"]>;
  court_room?: Maybe<Scalars["String"]>;
}

export interface BookingStatusInput {
  booking_number?: Maybe<Scalars["String"]>;
  court_appearance_datetime?: Maybe<Scalars["String"]>;
  court_reason?: Maybe<Scalars["String"]>;
  court_room?: Maybe<Scalars["String"]>;
}

export interface BookingToModify {
  __typename?: "BookingToModify";
  id: Scalars["String"];
  is_eligible: Scalars["Boolean"];
  has_wait?: Maybe<YesNo>;
  spanish_speaker?: Maybe<Scalars["String"]>;
  classification?: Maybe<Scalars["String"]>;
  comments: Scalars["String"];
}

export interface BookingToModifyInput {
  id: Scalars["String"];
  is_eligible: Scalars["Boolean"];
  has_wait?: Maybe<YesNo>;
  spanish_speaker?: Maybe<Scalars["String"]>;
  classification?: Maybe<Scalars["String"]>;
  comments: Scalars["String"];
  booking_status?: Maybe<BookingStatusInput>;
  booking_defendent?: Maybe<BookingDefendentInput>;
  booking_charges?: Maybe<Array<BookingChargeInput>>;
}

export interface BroadcastMessage {
  __typename?: "BroadcastMessage";
  id: Scalars["ID"];
  created_at: Scalars["String"];
  deployed_at?: Maybe<Scalars["String"]>;
  deployed: Scalars["Boolean"];
  scheduled: Scalars["Boolean"];
  text: Scalars["String"];
  to: BroadcastMessageRecipient;
  case_manager?: Maybe<CaseManager>;
  case_manager_id: Scalars["String"];
  time_to_deploy?: Maybe<Scalars["String"]>;
}

export interface BroadcastMessageRecipient {
  __typename?: "BroadcastMessageRecipient";
  queryType: BroadcastMessageRecipientType;
  arg?: Maybe<Scalars["String"]>;
}

export interface BroadcastMessageRecipientInput {
  queryType: BroadcastMessageRecipientType;
  arg?: Maybe<Scalars["String"]>;
}

export enum BroadcastMessageRecipientType {
  All = "all",
  Caseload = "caseload",
  RiskLevel = "risk_level",
  Activity = "activity",
  Program = "program",
  RiskLevelBySex = "risk_level_by_sex"
}

export enum BucketId {
  UpcomingUnreviewedCourtDate = "UpcomingUnreviewedCourtDate",
  FailureToAppear = "FailureToAppear",
  NoAttendanceData = "NoAttendanceData",
  NoFutureEvents = "NoFutureEvents",
  SupervisionDateReview = "SupervisionDateReview",
  NoAttendanceIntegrationData = "NoAttendanceIntegrationData",
  StaleDataInput = "StaleDataInput",
  RecentDataInput = "RecentDataInput",
  DataInputChange = "DataInputChange",
  OneYearAnniversary = "OneYearAnniversary",
  NoFutureCourtDate = "NoFutureCourtDate"
}

export enum CadenceOptions {
  Weekly = "weekly",
  Monthly = "monthly"
}

export interface CarePlanItem {
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  isCheckIn?: Maybe<Scalars["Boolean"]>;
  hasVerification?: Maybe<Scalars["Boolean"]>;
  checkInData?: Maybe<CheckInData>;
  obligationData?: Maybe<ObligationData>;
  serviceProviderId?: Maybe<Scalars["String"]>;
  activityId?: Maybe<Scalars["String"]>;
  verificationActivityId?: Maybe<Scalars["String"]>;
}

export interface Case {
  __typename?: "Case";
  id: Scalars["ID"];
  case_number: Scalars["String"];
  supervision_start_date?: Maybe<Scalars["String"]>;
  supervision_end_date?: Maybe<Scalars["String"]>;
  created_at: Scalars["String"];
  updated_at?: Maybe<Scalars["String"]>;
  housing?: Maybe<CaseNeed>;
  employment?: Maybe<CaseNeed>;
  education?: Maybe<CaseNeed>;
  transportation?: Maybe<CaseNeed>;
  did_recidivate?: Maybe<YesNoUnknown>;
  primary_treatment_facilities?: Maybe<Array<Maybe<IdField>>>;
  treatment_types?: Maybe<Array<Maybe<IdField>>>;
  case_type?: Maybe<Scalars["String"]>;
  medicated?: Maybe<YesNoUnknown>;
  program_result?: Maybe<Scalars["String"]>;
  courts?: Maybe<Array<Maybe<IdField>>>;
  group_meetings?: Maybe<Array<Maybe<IdField>>>;
  risk_level?: Maybe<Scalars["String"]>;
  assessment_score?: Maybe<Scalars["String"]>;
  recidivism_reason?: Maybe<RecidivismReason>;
  hand_off_to_probation?: Maybe<YesNoUnknown>;
  absconded?: Maybe<YesNoUnknown>;
  is_emh?: Maybe<YesNoUnknown>;
  risk_score?: Maybe<Scalars["String"]>;
}

export interface CaseManager {
  __typename?: "CaseManager";
  id: Scalars["ID"];
  name: PersonName;
  phone?: Maybe<PersonPhone>;
  email?: Maybe<Scalars["String"]>;
}

export interface CaseManagerPayload {
  id?: Maybe<Scalars["String"]>;
  first: Scalars["String"];
  last?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
}

export interface CaseNeed {
  __typename?: "CaseNeed";
  status?: Maybe<NeedStatus>;
}

export interface CaseStat {
  __typename?: "CaseStat";
  total: Scalars["Int"];
  number_completed_total: Scalars["Int"];
  number_recidivated_total: Scalars["Int"];
  number_completed_successful: Scalars["Int"];
  number_completed_unsuccessful: Scalars["Int"];
  number_recidivism: Scalars["Int"];
  number_no_recidivism: Scalars["Int"];
  year: Scalars["String"];
}

export interface CheckInData {
  cadence?: Maybe<CadenceOptions>;
  dayOfWeek?: Maybe<Scalars["Int"]>;
  dayOfMonth?: Maybe<Scalars["Int"]>;
  verificationType?: Maybe<VerificationOptions>;
}

export interface ConditionsOfRelease {
  __typename?: "ConditionsOfRelease";
  supervision_and_custody?: Maybe<YesNo>;
  not_leave_state_or_residence?: Maybe<YesNo>;
  notify_change_employment_or_residence?: Maybe<YesNo>;
  report_regularly_pretrial?: Maybe<YesNo>;
  report_to_probation?: Maybe<YesNo>;
  tcemp?: Maybe<YesNo>;
  tcv3a?: Maybe<YesNo>;
  curfew?: Maybe<YesNo>;
  location_tracking?: Maybe<YesNo>;
  alcohol_detection?: Maybe<YesNo>;
  alcohol_detection_with_curfew?: Maybe<YesNo>;
  follow_court_protective_orders?: Maybe<YesNo>;
  tcaap?: Maybe<YesNo>;
  tcbaf?: Maybe<YesNo>;
  tcnax?: Maybe<YesNo>;
  tcnae?: Maybe<YesNo>;
  tcgnx?: Maybe<YesNo>;
  tcgwr?: Maybe<YesNo>;
  follow_court_probation_conditions?: Maybe<YesNo>;
  pretrial_program?: Maybe<YesNo>;
}

export interface ConditionsOfReleaseInput {
  supervision_and_custody?: Maybe<YesNo>;
  not_leave_state_or_residence?: Maybe<YesNo>;
  notify_change_employment_or_residence?: Maybe<YesNo>;
  report_regularly_pretrial?: Maybe<YesNo>;
  report_to_probation?: Maybe<YesNo>;
  tcemp?: Maybe<YesNo>;
  tcv3a?: Maybe<YesNo>;
  curfew?: Maybe<YesNo>;
  location_tracking?: Maybe<YesNo>;
  alcohol_detection?: Maybe<YesNo>;
  alcohol_detection_with_curfew?: Maybe<YesNo>;
  follow_court_protective_orders?: Maybe<YesNo>;
  tcaap?: Maybe<YesNo>;
  tcbaf?: Maybe<YesNo>;
  tcnax?: Maybe<YesNo>;
  tcnae?: Maybe<YesNo>;
  tcgnx?: Maybe<YesNo>;
  tcgwr?: Maybe<YesNo>;
  follow_court_probation_conditions?: Maybe<YesNo>;
  pretrial_program?: Maybe<YesNo>;
}

export interface CourtMemo {
  __typename?: "CourtMemo";
  was_under_the_influence_of_alcohol_or_drugs?: Maybe<YesNo>;
  was_combative?: Maybe<YesNo>;
  was_in_medical_isolation?: Maybe<YesNo>;
  mental_state_prevented_interview?: Maybe<YesNo>;
  has_refused_interview?: Maybe<YesNo>;
  is_no_longer_in_custody?: Maybe<YesNo>;
  has_declined_interview_or_release?: Maybe<YesNo>;
  has_plan_to_bail_out?: Maybe<YesNo>;
  is_incomplete_due_to_insufficient_notice?: Maybe<YesNo>;
  report_available_by_date?: Maybe<Scalars["String"]>;
  has_existing_or_report_re_referred?: Maybe<YesNo>;
  existing_or_report_date?: Maybe<Scalars["String"]>;
  is_incomplete_due_to_referral_volume?: Maybe<YesNo>;
  is_denied_or?: Maybe<YesNo>;
  ordered_tad_date?: Maybe<Scalars["String"]>;
  has_existing_or_report_previously_interviewed?: Maybe<YesNo>;
  last_interviewed_date?: Maybe<Scalars["String"]>;
  hold_out_state?: Maybe<Scalars["String"]>;
  other_reason_comments?: Maybe<Scalars["String"]>;
  completed_by?: Maybe<CaseManager>;
  completed_at?: Maybe<Scalars["String"]>;
}

export interface CourtMemoInput {
  was_under_the_influence_of_alcohol_or_drugs?: Maybe<YesNo>;
  was_combative?: Maybe<YesNo>;
  was_in_medical_isolation?: Maybe<YesNo>;
  mental_state_prevented_interview?: Maybe<YesNo>;
  has_refused_interview?: Maybe<YesNo>;
  is_no_longer_in_custody?: Maybe<YesNo>;
  has_declined_interview_or_release?: Maybe<YesNo>;
  has_plan_to_bail_out?: Maybe<YesNo>;
  is_incomplete_due_to_insufficient_notice?: Maybe<YesNo>;
  report_available_by_date?: Maybe<Scalars["String"]>;
  has_existing_or_report_re_referred?: Maybe<YesNo>;
  existing_or_report_date?: Maybe<Scalars["String"]>;
  is_incomplete_due_to_referral_volume?: Maybe<YesNo>;
  is_denied_or?: Maybe<YesNo>;
  ordered_tad_date?: Maybe<Scalars["String"]>;
  has_existing_or_report_previously_interviewed?: Maybe<YesNo>;
  last_interviewed_date?: Maybe<Scalars["String"]>;
  hold_out_state?: Maybe<Scalars["String"]>;
  other_reason_comments?: Maybe<Scalars["String"]>;
}

export interface CriminalHistory {
  __typename?: "CriminalHistory";
  two_or_more_violent_convictions?: Maybe<YesNo>;
  one_or_more_misdemeanor_or_felony_convictions?: Maybe<YesNo>;
  no_prior_criminal_history?: Maybe<YesNo>;
}

export interface CriminalHistoryInput {
  two_or_more_violent_convictions?: Maybe<YesNo>;
  one_or_more_misdemeanor_or_felony_convictions?: Maybe<YesNo>;
  no_prior_criminal_history?: Maybe<YesNo>;
}

export interface DashboardBucket {
  __typename?: "DashboardBucket";
  key: BucketId;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
}

export enum DataInputField {
  Address = "address",
  Employment = "employment",
  LawEnforcementContact = "law_enforcement_contact",
  AaMeeting = "aa_meeting"
}

export interface DataInputInput {
  data_input_type: DataInputType;
  data_input_field: DataInputField;
  days_between_inputs: Scalars["Int"];
}

export enum DataInputType {
  Verification = "verification",
  Confirmation = "confirmation"
}

export interface DateRange {
  __typename?: "DateRange";
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
}

export interface DbIntegrationConfig {
  __typename?: "DbIntegrationConfig";
  source?: Maybe<Scalars["String"]>;
}

export enum DeactivatedReason {
  Incarcerated = "incarcerated",
  NonCompliant = "non_compliant",
  Success = "success",
  TransferredAlternative = "transferred_alternative",
  TransferredAnotherCounty = "transferred_another_county",
  TransferredTreatment = "transferred_treatment",
  Nonstarter = "nonstarter",
  Deceased = "deceased",
  BenchWarrant = "bench_warrant",
  Other = "other"
}

export interface DeleteEventTemplate {
  __typename?: "DeleteEventTemplate";
  event_template_id?: Maybe<Scalars["String"]>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface DeleteObligation {
  __typename?: "DeleteObligation";
  obligation_id?: Maybe<Scalars["String"]>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface DeleteObligationEvent {
  __typename?: "DeleteObligationEvent";
  event_id?: Maybe<Scalars["String"]>;
  event_template_id?: Maybe<Scalars["String"]>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface DeleteParticipantNote {
  __typename?: "DeleteParticipantNote";
  participant_id?: Maybe<Scalars["String"]>;
  participant_note_id?: Maybe<Scalars["String"]>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
}

export enum Employment {
  Employed = "employed",
  FullTimeStudent = "full_time_student",
  PrimaryCaregiver = "primary_caregiver",
  Retired = "retired",
  None = "none"
}

export interface EmploymentAndEducationInfo {
  __typename?: "EmploymentAndEducationInfo";
  is_employed?: Maybe<YesNo>;
  current_employer_name?: Maybe<Scalars["String"]>;
  is_student_retired_disabled?: Maybe<YesNo>;
  is_primary_caretaker?: Maybe<YesNo>;
  employment_start_date?: Maybe<Scalars["String"]>;
  employment_type?: Maybe<Scalars["String"]>;
  hours_worked_per_week?: Maybe<Scalars["Int"]>;
  type_of_work?: Maybe<Scalars["String"]>;
  previous_employer_name?: Maybe<Scalars["String"]>;
  previous_employer_start_date?: Maybe<Scalars["String"]>;
  previous_employer_end_date?: Maybe<Scalars["String"]>;
  unemployed_start_date?: Maybe<Scalars["String"]>;
  unemployed_how_supported?: Maybe<Scalars["String"]>;
}

export interface EmploymentAndEducationInfoInput {
  is_employed?: Maybe<YesNo>;
  current_employer_name?: Maybe<Scalars["String"]>;
  is_student_retired_disabled?: Maybe<YesNo>;
  is_primary_caretaker?: Maybe<YesNo>;
  employment_start_date?: Maybe<Scalars["String"]>;
  employment_type?: Maybe<Scalars["String"]>;
  hours_worked_per_week?: Maybe<Scalars["Int"]>;
  type_of_work?: Maybe<Scalars["String"]>;
  previous_employer_name?: Maybe<Scalars["String"]>;
  previous_employer_start_date?: Maybe<Scalars["String"]>;
  previous_employer_end_date?: Maybe<Scalars["String"]>;
  unemployed_start_date?: Maybe<Scalars["String"]>;
  unemployed_how_supported?: Maybe<Scalars["String"]>;
}

export interface Event {
  __typename?: "Event";
  acknowledged?: Maybe<Scalars["Boolean"]>;
  activity: Activity;
  attended?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  fs_path: Scalars["String"];
  geo?: Maybe<GeoLocation>;
  id?: Maybe<Scalars["ID"]>;
  obligation?: Maybe<Obligation>;
  participant_id?: Maybe<Scalars["String"]>;
  event_template?: Maybe<EventTemplate>;
  required?: Maybe<Scalars["Boolean"]>;
  service_provider: ServiceProvider;
  time_specified?: Maybe<Scalars["Boolean"]>;
  disposition?: Maybe<Scalars["String"]>;
  input_prompt?: Maybe<I18nText>;
  input_field_name?: Maybe<DataInputField>;
  input_response_text?: Maybe<Scalars["String"]>;
  input_response_media_url?: Maybe<Scalars["String"]>;
  input_response_media_base64?: Maybe<Scalars["String"]>;
  input_response_media_mime_type?: Maybe<Scalars["String"]>;
  input_changed?: Maybe<Scalars["Boolean"]>;
  offline_mode?: Maybe<Scalars["String"]>;
}

export enum EventAttendence {
  Yes = "yes",
  No = "no",
  Excused = "excused",
  Rescheduled = "rescheduled"
}

export interface EventTemplate {
  __typename?: "EventTemplate";
  id?: Maybe<Scalars["ID"]>;
  exdate?: Maybe<Scalars["String"]>;
  rrule?: Maybe<Scalars["String"]>;
  events?: Maybe<Array<Maybe<Event>>>;
}

export interface FinesAndFees {
  __typename?: "FinesAndFees";
  fines_and_fees_amount_owed?: Maybe<Scalars["Int"]>;
  fines_and_fees_last_payment_amount?: Maybe<Scalars["Int"]>;
  fines_and_fees_last_payment_date?: Maybe<Scalars["String"]>;
  fines_and_fees_expected_payment_amount?: Maybe<Scalars["Int"]>;
  restitution_amount_owed?: Maybe<Scalars["Int"]>;
  restitution_last_payment_amount?: Maybe<Scalars["Int"]>;
  restitution_last_payment_date?: Maybe<Scalars["String"]>;
  restitution_expected_payment_amount?: Maybe<Scalars["Int"]>;
}

export interface FirearmInfo {
  __typename?: "FirearmInfo";
  has_firearm_registered?: Maybe<YesNo>;
  registered_firearm_model?: Maybe<Scalars["String"]>;
  registered_firearm_location?: Maybe<Scalars["String"]>;
  has_firearm_in_home?: Maybe<YesNo>;
  home_firearm_model?: Maybe<Scalars["String"]>;
  home_firearm_location?: Maybe<Scalars["String"]>;
}

export interface FirearmInfoInput {
  has_firearm_registered?: Maybe<YesNo>;
  registered_firearm_model?: Maybe<Scalars["String"]>;
  registered_firearm_location?: Maybe<Scalars["String"]>;
  has_firearm_in_home?: Maybe<YesNo>;
  home_firearm_model?: Maybe<Scalars["String"]>;
  home_firearm_location?: Maybe<Scalars["String"]>;
}

export interface Flags {
  __typename?: "Flags";
  all?: Maybe<Scalars["Object"]>;
  chat_enabled?: Maybe<Scalars["Boolean"]>;
  documents_enabled?: Maybe<Scalars["Boolean"]>;
  tasks_goals_enabled?: Maybe<Scalars["Boolean"]>;
}

export type FlagsAllArgs = {
  flag_ids?: Maybe<Scalars["String"]>;
};

export interface GeoLocation {
  __typename?: "GeoLocation";
  geometry?: Maybe<GeoLocationGeometry>;
  place_id?: Maybe<Scalars["String"]>;
}

export interface GeoLocationGeometry {
  __typename?: "GeoLocationGeometry";
  location?: Maybe<LatLng>;
}

export interface GoalPayload {
  id?: Maybe<Scalars["ID"]>;
  label: Scalars["String"];
  completed_at?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["String"]>;
  participant_id: Scalars["String"];
}

export interface GroupClass {
  __typename?: "GroupClass";
  id: Scalars["ID"];
  participants: Array<Participant>;
  start_date: Scalars["String"];
  end_date: Scalars["String"];
  exclude_dates: Array<Scalars["String"]>;
  service_provider: ServiceProvider;
  activity: Activity;
  days_of_week: Array<Scalars["String"]>;
  rruleSetString: Scalars["String"];
}

export interface I18n {
  __typename?: "I18n";
  descriptions?: Maybe<Scalars["Object"]>;
}

export interface I18nText {
  __typename?: "i18nText";
  en?: Maybe<Scalars["String"]>;
  es?: Maybe<Scalars["String"]>;
}

export interface I18nTextInput {
  en?: Maybe<Scalars["String"]>;
  es?: Maybe<Scalars["String"]>;
}

export enum IdCardType {
  Unknown = "unknown",
  License = "license",
  StateId = "state_id"
}

export interface IdField {
  __typename?: "IdField";
  id: Scalars["String"];
}

export interface IdInput {
  id: Scalars["String"];
}

export enum IncarcerationStatus {
  Sentenced = "sentenced",
  Pretrial = "pretrial"
}

export interface Integration {
  __typename?: "Integration";
  id?: Maybe<Scalars["ID"]>;
  config?: Maybe<DbIntegrationConfig>;
  completed_at?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["String"]>;
  failed_at?: Maybe<Scalars["String"]>;
  integration_id?: Maybe<Scalars["String"]>;
  result?: Maybe<Scalars["String"]>;
  started_at?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  work_items_count?: Maybe<Scalars["Int"]>;
  work_items_failed_count?: Maybe<Scalars["Int"]>;
  work_items_ignored_count?: Maybe<Scalars["Int"]>;
  work_items_processed_count?: Maybe<Scalars["Int"]>;
}

export interface IntegrationPayload {
  activity?: Maybe<ActivityPayload>;
  case_manager?: Maybe<CaseManagerPayload>;
  participant?: Maybe<ParticipantPayload>;
  obligation?: Maybe<ObligationPayload>;
  reminder?: Maybe<ReminderTemplatePayload>;
  service_provider?: Maybe<ServiceProviderPayload>;
  obligation_event?: Maybe<ObligationEventPayload>;
  goal?: Maybe<GoalPayload>;
  task?: Maybe<TaskPayload>;
  booking?: Maybe<BookingPayload>;
}

export interface IntegrationWorkItem {
  integration_id: Scalars["String"];
  integration_ref: Scalars["String"];
  payload?: Maybe<IntegrationPayload>;
}

export interface InterviewAndAssessment {
  __typename?: "InterviewAndAssessment";
  interview_data?: Maybe<InterviewData>;
  research_factors?: Maybe<ResearchFactor>;
  risk_factors?: Maybe<RiskFactor>;
  completed_by?: Maybe<CaseManager>;
  court_date?: Maybe<Scalars["String"]>;
  charge_bond_type_amount_court?: Maybe<Scalars["String"]>;
  instrument_completed_at?: Maybe<Scalars["String"]>;
  last_updated_at?: Maybe<Scalars["String"]>;
}

export interface InterviewData {
  __typename?: "InterviewData";
  residence?: Maybe<ResidenceInfo>;
  contacts?: Maybe<Array<ParticipantContact>>;
  employment_and_education?: Maybe<EmploymentAndEducationInfo>;
  prior_record?: Maybe<PriorRecordInfo>;
  substance_abuse?: Maybe<SubstanceAbuseInfo>;
  victim_info?: Maybe<VictimInfo>;
  firearm_info?: Maybe<FirearmInfo>;
}

export interface InterviewDataInput {
  residence: ResidenceInfoInput;
  contacts?: Maybe<Array<Maybe<ParticipantContactInput>>>;
  employment_and_education: EmploymentAndEducationInfoInput;
  prior_record: PriorRecordInfoInput;
  substance_abuse: SubstanceAbuseInfoInput;
  victim_info: VictimInfoInput;
  firearm_info: FirearmInfoInput;
}

export interface KeyedString {
  __typename?: "KeyedString";
  key: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
}

export interface LatLng {
  __typename?: "LatLng";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
}

export interface LogEntry {
  __typename?: "LogEntry";
  id: Scalars["ID"];
  log_type: LogEntryType;
  log_category?: Maybe<Scalars["String"]>;
  effective_timestamp: Scalars["String"];
  timestamp: Scalars["String"];
  participant?: Maybe<Participant>;
  event?: Maybe<Event>;
  details?: Maybe<Scalars["Object"]>;
  notification?: Maybe<Notification>;
}

export enum LogEntryType {
  CsvImportAddEventsError = "CSVImportAddEventsError",
  CsvImportAddReminderTemplatesError = "CSVImportAddReminderTemplatesError",
  CsvImportStart = "CSVImportStart",
  CsvImportSuccess = "CSVImportSuccess",
  CsvImportDownloadComplete = "CSVImportDownloadComplete",
  CsvImportDownloadError = "CSVImportDownloadError",
  CreateReviewItemError = "CreateReviewItemError",
  ReviewItemForcedExpiry = "ReviewItemForcedExpiry",
  EventCreated = "EventCreated",
  EventDeleted = "EventDeleted",
  EventUpdated = "EventUpdated",
  MessageOnNotificationSent = "MessageOnNotificationSent",
  NotificationDelete = "NotificationDelete",
  NotificationDeliverySuccess = "NotificationDeliverySuccess",
  NotificationDeliveryFailure = "NotificationDeliveryFailure",
  NotificationDeploy = "NotificationDeploy",
  NotificationMessageParticipant = "NotificationMessageParticipant",
  NotificationQueue = "NotificationQueue",
  IntegrationStart = "IntegrationStart",
  IntegrationEnd = "IntegrationEnd",
  IntegrationError = "IntegrationError",
  ObligationCreated = "ObligationCreated",
  ObligationDeleted = "ObligationDeleted",
  ObligationUpdated = "ObligationUpdated",
  ParticipantLogin = "ParticipantLogin",
  ParticipantUpdated = "ParticipantUpdated",
  ParticipantActiveStatusChanged = "ParticipantActiveStatusChanged",
  ParticipantDeleted = "ParticipantDeleted",
  ParticipantPhoneUpdated = "ParticipantPhoneUpdated",
  ServiceProviderUpdated = "ServiceProviderUpdated",
  StatsError = "StatsError"
}

export enum LogSubType {
  Timeline = "Timeline"
}

export interface Me {
  __typename?: "Me";
  email: Scalars["String"];
  case_manager?: Maybe<CaseManager>;
  zendesk?: Maybe<ZenDesk>;
}

export interface Message {
  __typename?: "Message";
  id: Scalars["ID"];
  participant_id?: Maybe<Scalars["String"]>;
  case_manager_id?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["String"]>;
  sender_type?: Maybe<MessageActor>;
  recipient_type?: Maybe<MessageActor>;
  read_at?: Maybe<Scalars["String"]>;
  sent_as?: Maybe<Array<Maybe<Scalars["String"]>>>;
  delivery_status?: Maybe<MessageStatus>;
  sender?: Maybe<MessageSender>;
  group_id?: Maybe<Scalars["String"]>;
  group_message_id?: Maybe<Scalars["String"]>;
  uid?: Maybe<Scalars["String"]>;
  sender_id?: Maybe<Scalars["String"]>;
}

export enum MessageActor {
  CaseManager = "case_manager",
  Participant = "participant",
  System = "system",
  PersonGroup = "person_group"
}

export interface MessageReadReceipt {
  id: Scalars["String"];
  read_by?: Maybe<MessageActor>;
}

export interface MessageSender {
  __typename?: "MessageSender";
  uid: Scalars["String"];
  name?: Maybe<PersonName>;
  sender_type?: Maybe<Scalars["String"]>;
}

export enum MessageStatus {
  Succeeded = "succeeded",
  Failed = "failed"
}

export interface MinMaxInput {
  min: Scalars["Int"];
  max?: Maybe<Scalars["Int"]>;
}

export interface MinVersion {
  __typename?: "MinVersion";
  soft: Scalars["String"];
  hard: Scalars["String"];
}

export interface MobileApp {
  __typename?: "MobileApp";
  chat_supported?: Maybe<Scalars["Boolean"]>;
  min_version: MinVersion;
  language?: Maybe<Scalars["String"]>;
  participant_id?: Maybe<Scalars["String"]>;
  has_push_token?: Maybe<Scalars["Boolean"]>;
  is_test_user?: Maybe<Scalars["Boolean"]>;
}

export interface Mutation {
  __typename?: "Mutation";
  addNotificationToken?: Maybe<MutationResponse>;
  addMobileDevice?: Maybe<MutationResponse>;
  acknowledgeNotification?: Maybe<MutationResponse>;
  addGlobalPhoneAndAuthToken?: Maybe<MutationResponse>;
  removeGlobalPhoneAndAuthToken?: Maybe<MutationResponse>;
  addParticipant?: Maybe<NewParticipant>;
  addParticipantTask?: Maybe<NewParticipantTask>;
  removeParticipantTask?: Maybe<NewParticipantTask>;
  updateParticipantTask?: Maybe<NewParticipantTask>;
  addParticipantGoal?: Maybe<NewParticipantGoal>;
  updateParticipantGoal?: Maybe<NewParticipantGoal>;
  removeParticipantGoal?: Maybe<NewParticipantGoal>;
  addCaseManager?: Maybe<MutationResponse>;
  addObligation?: Maybe<NewObligation>;
  addObligationEvent?: Maybe<NewEvent>;
  deleteObligation?: Maybe<DeleteObligation>;
  deleteObligationEvent?: Maybe<DeleteObligationEvent>;
  updateObligationEvent?: Maybe<UpdateObligationEvent>;
  deleteEventTemplate?: Maybe<DeleteEventTemplate>;
  addServiceProvider?: Maybe<MutationResponse>;
  addActivity?: Maybe<MutationResponse>;
  dataInput?: Maybe<MutationResponse>;
  updateActivity?: Maybe<MutationResponse>;
  updateParticipant?: Maybe<NewParticipant>;
  updateServiceProvider?: Maybe<MutationResponse>;
  updateObligation?: Maybe<NewObligation>;
  integrationAddItemToProcess?: Maybe<MutationResponse>;
  integrationValidate?: Maybe<MutationResponse>;
  integrationStart?: Maybe<MutationResponse>;
  addBroadcastMessageFromCaseManager?: Maybe<NewBroadcastMessage>;
  updateBroadcastMessageFromCaseManager?: Maybe<NewBroadcastMessage>;
  deleteBroadcastMessageFromCaseManager?: Maybe<MutationResponse>;
  addMessageFromCaseManager?: Maybe<NewMessage>;
  addMessageFromParticipant?: Maybe<MutationResponse>;
  addMessageFromSystem?: Maybe<MutationResponse>;
  updateMessage?: Maybe<MutationResponse>;
  markMessagesAsRead?: Maybe<MutationResponse>;
  addParticipantNote?: Maybe<NewParticipant>;
  deleteParticipantNote?: Maybe<NewParticipant>;
  updateParticipantNote?: Maybe<NewParticipant>;
  reviewReviewItem?: Maybe<ReviewReviewItem>;
  logOutParticipant?: Maybe<MutationResponse>;
  changeParticipantIsActiveStatus?: Maybe<NewParticipant>;
  addParticipantDocument?: Maybe<NewParticipantDocument>;
  softDeleteParticipantDocument?: Maybe<NewParticipantDocument>;
  addAssessmentTemplate?: Maybe<NewAssessmentTemplate>;
  addParticipantAssessmentResult?: Maybe<NewParticipantAssessmentResult>;
  scoreParticipantAssessmentResult?: Maybe<NewParticipantAssessmentResult>;
  addCase?: Maybe<NewCaseResult>;
  updateCase?: Maybe<NewCaseResult>;
  addGroupClass?: Maybe<NewGroupClassResult>;
  deleteGroupClass?: Maybe<NewGroupClassResult>;
  addBooking?: Maybe<NewBookingResult>;
  updateBookingClassification?: Maybe<NewBookingResult>;
  addBookingGroup?: Maybe<NewBookingGroupResult>;
  emailTransmittal?: Maybe<NewBookingGroupResult>;
  addObligationsFromCarePlanItems?: Maybe<
    AddObligationsFromCarePlanItemsResult
  >;
  editCheckInObligationCadenceSettings?: Maybe<NewObligation>;
  startBookingInterviewAndAssessment?: Maybe<NewBookingResult>;
  declineBookingInterview?: Maybe<NewBookingResult>;
  updateBookingInterviewAndAssessment?: Maybe<NewBookingResult>;
  submitBookingInterviewAndAssessment?: Maybe<NewBookingResult>;
  submitBookingCourtMemo?: Maybe<NewBookingResult>;
  updateBookingInterviewData?: Maybe<NewBookingResult>;
  updateBookingRecommendationForm?: Maybe<NewBookingResult>;
  submitBookingRecommendationFormForReview?: Maybe<NewBookingResult>;
  reviewBookingRecommendationForm?: Maybe<NewBookingResult>;
  addNoteToBookingRecommendationForm?: Maybe<NewBookingResult>;
  submitBookingRecommendationForm?: Maybe<NewBookingResult>;
  revokeBookingRecommendationApproval?: Maybe<NewBookingResult>;
  updateBookingInfoData?: Maybe<NewBookingResult>;
  updateBookingCourtDecisionData?: Maybe<NewBookingResult>;
  archiveBooking?: Maybe<NewBookingResult>;
  createParticipantFromBooking?: Maybe<NewBookingResult>;
  addBookingRelease?: Maybe<NewBookingResult>;
}

export type MutationAddNotificationTokenArgs = {
  token?: Maybe<Scalars["String"]>;
  device_id?: Maybe<Scalars["String"]>;
};

export type MutationAddMobileDeviceArgs = {
  device_id?: Maybe<Scalars["String"]>;
  device_name?: Maybe<Scalars["String"]>;
  platform?: Maybe<Scalars["String"]>;
  app_version?: Maybe<Scalars["String"]>;
};

export type MutationAcknowledgeNotificationArgs = {
  notification_id?: Maybe<Scalars["String"]>;
};

export type MutationAddGlobalPhoneAndAuthTokenArgs = {
  phone_number?: Maybe<Scalars["String"]>;
  auth_token?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
};

export type MutationRemoveGlobalPhoneAndAuthTokenArgs = {
  phone_number?: Maybe<Scalars["String"]>;
  auth_token?: Maybe<Scalars["String"]>;
};

export type MutationAddParticipantArgs = {
  id?: Maybe<Scalars["String"]>;
  first: Scalars["String"];
  last?: Maybe<Scalars["String"]>;
  middle?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  home_phone?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  assessment_score?: Maybe<Scalars["String"]>;
  assessment_tool?: Maybe<Scalars["String"]>;
  risk_level?: Maybe<Scalars["String"]>;
  sms_enabled?: Maybe<Scalars["Boolean"]>;
  sms_disabled_by?: Maybe<SmsMessageActor>;
  sms_consent?: Maybe<Scalars["Boolean"]>;
  sms_can_enable?: Maybe<Scalars["Boolean"]>;
  is_active?: Maybe<Scalars["Boolean"]>;
  date_of_birth?: Maybe<Scalars["String"]>;
  race?: Maybe<PersonRace>;
  hispanic_or_latino?: Maybe<Scalars["Boolean"]>;
  participant_id?: Maybe<Scalars["String"]>;
  preferred_name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  compliance?: Maybe<Scalars["String"]>;
  supervision_begin_date?: Maybe<Scalars["String"]>;
  supervision_end_date?: Maybe<Scalars["String"]>;
  program_status?: Maybe<ProgramStatus>;
  recidivism_date?: Maybe<Scalars["String"]>;
  recidivism_reason?: Maybe<RecidivismReason>;
  incarceration_status?: Maybe<IncarcerationStatus>;
  deactivated_reason?: Maybe<DeactivatedReason>;
  address?: Maybe<Scalars["String"]>;
  employment?: Maybe<Scalars["String"]>;
  law_enforcement_contact?: Maybe<YesNo>;
  aa_meeting?: Maybe<YesNo>;
  phase?: Maybe<Scalars["String"]>;
  points?: Maybe<Scalars["Int"]>;
  alcohol_monitoring?: Maybe<Scalars["Boolean"]>;
  gps_monitoring?: Maybe<Scalars["Boolean"]>;
  sex?: Maybe<PersonSex>;
  employer_name?: Maybe<Scalars["String"]>;
  eye_color?: Maybe<PersonEyeColor>;
  hair?: Maybe<PersonHair>;
  height?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["String"]>;
  person_address?: Maybe<Scalars["String"]>;
  place_of_birth?: Maybe<Scalars["String"]>;
  id_number?: Maybe<Scalars["String"]>;
  id_type?: Maybe<IdCardType>;
  id_state?: Maybe<Scalars["String"]>;
  victims?: Maybe<Array<Maybe<ParticipantVictimInput>>>;
  stay_away?: Maybe<Scalars["Boolean"]>;
  peaceful_contact?: Maybe<Scalars["Boolean"]>;
  case_number?: Maybe<Scalars["String"]>;
  extra_equipment?: Maybe<Scalars["String"]>;
  incident_notes?: Maybe<Scalars["String"]>;
  offense_info?: Maybe<Scalars["String"]>;
  base64_media?: Maybe<Scalars["String"]>;
  mime_type?: Maybe<Scalars["String"]>;
  fines_and_fees_amount_owed?: Maybe<Scalars["Int"]>;
  fines_and_fees_last_payment_amount?: Maybe<Scalars["Int"]>;
  fines_and_fees_last_payment_date?: Maybe<Scalars["String"]>;
  fines_and_fees_expected_payment_amount?: Maybe<Scalars["Int"]>;
  restitution_amount_owed?: Maybe<Scalars["Int"]>;
  restitution_last_payment_amount?: Maybe<Scalars["Int"]>;
  restitution_last_payment_date?: Maybe<Scalars["String"]>;
  restitution_expected_payment_amount?: Maybe<Scalars["Int"]>;
  record_source?: Maybe<Scalars["String"]>;
  court_recommendation?: Maybe<Scalars["String"]>;
  court_decision?: Maybe<Scalars["String"]>;
  case_disposition?: Maybe<Scalars["String"]>;
  equipment_returned?: Maybe<Array<Maybe<Scalars["String"]>>>;
  protective_order_notes?: Maybe<Scalars["String"]>;
  probation_number?: Maybe<Scalars["String"]>;
  case_manager_id?: Maybe<Scalars["String"]>;
  secondary_case_manager_id?: Maybe<Scalars["String"]>;
};

export type MutationAddParticipantTaskArgs = {
  participant_id: Scalars["String"];
  label: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
  due_at?: Maybe<Scalars["String"]>;
  is_completed?: Maybe<Scalars["Boolean"]>;
};

export type MutationRemoveParticipantTaskArgs = {
  participant_id: Scalars["String"];
  task_id: Scalars["String"];
};

export type MutationUpdateParticipantTaskArgs = {
  participant_id: Scalars["String"];
  task_id: Scalars["String"];
  is_completed?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<Scalars["String"]>;
};

export type MutationAddParticipantGoalArgs = {
  participant_id: Scalars["String"];
  label: Scalars["String"];
  is_completed?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpdateParticipantGoalArgs = {
  participant_id: Scalars["String"];
  goal_id: Scalars["String"];
  is_completed?: Maybe<Scalars["Boolean"]>;
  label?: Maybe<Scalars["String"]>;
};

export type MutationRemoveParticipantGoalArgs = {
  participant_id: Scalars["String"];
  goal_id: Scalars["String"];
};

export type MutationAddCaseManagerArgs = {
  id?: Maybe<Scalars["String"]>;
  first: Scalars["String"];
  last?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export type MutationAddObligationArgs = {
  id?: Maybe<Scalars["ID"]>;
  activity_id?: Maybe<Scalars["String"]>;
  participant_id?: Maybe<Scalars["String"]>;
  service_provider_id?: Maybe<Scalars["String"]>;
  description_en?: Maybe<Scalars["String"]>;
  title_en?: Maybe<Scalars["String"]>;
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  compliance?: Maybe<Scalars["String"]>;
  sub_address_for_event?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  group_class_id?: Maybe<Scalars["String"]>;
  cadence_option?: Maybe<Scalars["String"]>;
  day_of_week?: Maybe<Scalars["Int"]>;
  day_of_month?: Maybe<Scalars["Int"]>;
  verification_option?: Maybe<Scalars["String"]>;
  verification_obligation_ref?: Maybe<Scalars["String"]>;
};

export type MutationAddObligationEventArgs = {
  id?: Maybe<Scalars["ID"]>;
  rrule?: Maybe<Scalars["String"]>;
  timezone?: Maybe<Scalars["String"]>;
  obligation_id: Scalars["String"];
  date?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  attended?: Maybe<EventAttendence>;
  time_specified?: Maybe<Scalars["Boolean"]>;
  disposition?: Maybe<Scalars["String"]>;
  should_delete?: Maybe<Scalars["Boolean"]>;
};

export type MutationDeleteObligationArgs = {
  obligation_id: Scalars["String"];
};

export type MutationDeleteObligationEventArgs = {
  obligation_id: Scalars["String"];
  event_id: Scalars["String"];
};

export type MutationUpdateObligationEventArgs = {
  obligation_id: Scalars["String"];
  event_id: Scalars["String"];
  event_data: Scalars["Object"];
};

export type MutationDeleteEventTemplateArgs = {
  obligation_id: Scalars["String"];
  event_template_id: Scalars["String"];
};

export type MutationAddServiceProviderArgs = {
  id?: Maybe<Scalars["String"]>;
  address?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type MutationAddActivityArgs = {
  id: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
  en?: Maybe<Scalars["String"]>;
  es?: Maybe<Scalars["String"]>;
  reminder_id?: Maybe<Scalars["String"]>;
  data_input?: Maybe<DataInputInput>;
};

export type MutationDataInputArgs = {
  field: DataInputField;
  value?: Maybe<Scalars["String"]>;
  participant_id: Scalars["String"];
  input_type: DataInputType;
  base64_media?: Maybe<Scalars["String"]>;
  mime_type?: Maybe<Scalars["String"]>;
  offline_mode?: Maybe<Scalars["String"]>;
};

export type MutationUpdateActivityArgs = {
  id: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
  en?: Maybe<Scalars["String"]>;
  es?: Maybe<Scalars["String"]>;
  reminder_id?: Maybe<Scalars["String"]>;
  data_input?: Maybe<DataInputInput>;
};

export type MutationUpdateParticipantArgs = {
  id: Scalars["String"];
  first?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["String"]>;
  middle?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  home_phone?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  assessment_score?: Maybe<Scalars["String"]>;
  assessment_tool?: Maybe<Scalars["String"]>;
  risk_level?: Maybe<Scalars["String"]>;
  sms_enabled?: Maybe<Scalars["Boolean"]>;
  sms_disabled_by?: Maybe<SmsMessageActor>;
  sms_consent?: Maybe<Scalars["Boolean"]>;
  sms_can_enable?: Maybe<Scalars["Boolean"]>;
  is_active?: Maybe<Scalars["Boolean"]>;
  date_of_birth?: Maybe<Scalars["String"]>;
  race?: Maybe<PersonRace>;
  hispanic_or_latino?: Maybe<Scalars["Boolean"]>;
  participant_id?: Maybe<Scalars["String"]>;
  preferred_name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  compliance?: Maybe<Scalars["String"]>;
  supervision_begin_date?: Maybe<Scalars["String"]>;
  supervision_end_date?: Maybe<Scalars["String"]>;
  program_status?: Maybe<ProgramStatus>;
  recidivism_date?: Maybe<Scalars["String"]>;
  recidivism_reason?: Maybe<RecidivismReason>;
  incarceration_status?: Maybe<IncarcerationStatus>;
  deactivated_reason?: Maybe<DeactivatedReason>;
  address?: Maybe<Scalars["String"]>;
  employment?: Maybe<Scalars["String"]>;
  law_enforcement_contact?: Maybe<YesNo>;
  aa_meeting?: Maybe<YesNo>;
  phase?: Maybe<Scalars["String"]>;
  points?: Maybe<Scalars["Int"]>;
  alcohol_monitoring?: Maybe<Scalars["Boolean"]>;
  gps_monitoring?: Maybe<Scalars["Boolean"]>;
  sex?: Maybe<PersonSex>;
  employer_name?: Maybe<Scalars["String"]>;
  eye_color?: Maybe<PersonEyeColor>;
  hair?: Maybe<PersonHair>;
  height?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["String"]>;
  person_address?: Maybe<Scalars["String"]>;
  place_of_birth?: Maybe<Scalars["String"]>;
  id_number?: Maybe<Scalars["String"]>;
  id_type?: Maybe<IdCardType>;
  id_state?: Maybe<Scalars["String"]>;
  victims?: Maybe<Array<Maybe<ParticipantVictimInput>>>;
  stay_away?: Maybe<Scalars["Boolean"]>;
  peaceful_contact?: Maybe<Scalars["Boolean"]>;
  case_number?: Maybe<Scalars["String"]>;
  extra_equipment?: Maybe<Scalars["String"]>;
  incident_notes?: Maybe<Scalars["String"]>;
  offense_info?: Maybe<Scalars["String"]>;
  base64_media?: Maybe<Scalars["String"]>;
  mime_type?: Maybe<Scalars["String"]>;
  fines_and_fees_amount_owed?: Maybe<Scalars["Int"]>;
  fines_and_fees_last_payment_amount?: Maybe<Scalars["Int"]>;
  fines_and_fees_last_payment_date?: Maybe<Scalars["String"]>;
  fines_and_fees_expected_payment_amount?: Maybe<Scalars["Int"]>;
  restitution_amount_owed?: Maybe<Scalars["Int"]>;
  restitution_last_payment_amount?: Maybe<Scalars["Int"]>;
  restitution_last_payment_date?: Maybe<Scalars["String"]>;
  restitution_expected_payment_amount?: Maybe<Scalars["Int"]>;
  record_source?: Maybe<Scalars["String"]>;
  court_recommendation?: Maybe<Scalars["String"]>;
  court_decision?: Maybe<Scalars["String"]>;
  case_disposition?: Maybe<Scalars["String"]>;
  equipment_returned?: Maybe<Array<Maybe<Scalars["String"]>>>;
  protective_order_notes?: Maybe<Scalars["String"]>;
  probation_number?: Maybe<Scalars["String"]>;
  case_manager_id?: Maybe<Scalars["String"]>;
  secondary_case_manager_id?: Maybe<Scalars["String"]>;
};

export type MutationUpdateServiceProviderArgs = {
  id: Scalars["String"];
  address?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type MutationUpdateObligationArgs = {
  obligation_id: Scalars["ID"];
  activity_id?: Maybe<Scalars["String"]>;
  participant_id?: Maybe<Scalars["String"]>;
  service_provider_id?: Maybe<Scalars["String"]>;
  description_en?: Maybe<Scalars["String"]>;
  title_en?: Maybe<Scalars["String"]>;
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  compliance?: Maybe<Scalars["String"]>;
  sub_address_for_event?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  group_class_id?: Maybe<Scalars["String"]>;
  cadence_option?: Maybe<Scalars["String"]>;
  day_of_week?: Maybe<Scalars["Int"]>;
  day_of_month?: Maybe<Scalars["Int"]>;
  verification_option?: Maybe<Scalars["String"]>;
  verification_obligation_ref?: Maybe<Scalars["String"]>;
};

export type MutationIntegrationAddItemToProcessArgs = {
  integration_work_item: IntegrationWorkItem;
};

export type MutationIntegrationValidateArgs = {
  integration_id: Scalars["String"];
  source: Scalars["String"];
};

export type MutationIntegrationStartArgs = {
  integration_id: Scalars["String"];
};

export type MutationAddBroadcastMessageFromCaseManagerArgs = {
  text: Scalars["String"];
  to: BroadcastMessageRecipientInput;
  time_to_deploy?: Maybe<Scalars["String"]>;
};

export type MutationUpdateBroadcastMessageFromCaseManagerArgs = {
  id: Scalars["String"];
  text?: Maybe<Scalars["String"]>;
  to?: Maybe<BroadcastMessageRecipientInput>;
  time_to_deploy?: Maybe<Scalars["String"]>;
};

export type MutationDeleteBroadcastMessageFromCaseManagerArgs = {
  id: Scalars["String"];
};

export type MutationAddMessageFromCaseManagerArgs = {
  text?: Maybe<Scalars["String"]>;
  participant_id?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["String"]>;
  risk_level?: Maybe<Scalars["String"]>;
};

export type MutationAddMessageFromParticipantArgs = {
  text?: Maybe<Scalars["String"]>;
  case_manager_id?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["String"]>;
  sent_as?: Maybe<Scalars["String"]>;
};

export type MutationAddMessageFromSystemArgs = {
  text?: Maybe<Scalars["String"]>;
  participant_id?: Maybe<Scalars["String"]>;
  case_manager_id?: Maybe<Scalars["String"]>;
  sent_as?: Maybe<Scalars["String"]>;
};

export type MutationUpdateMessageArgs = {
  id: Scalars["String"];
  read_by?: Maybe<MessageActor>;
};

export type MutationMarkMessagesAsReadArgs = {
  read_message_receipts: Array<Maybe<MessageReadReceipt>>;
};

export type MutationAddParticipantNoteArgs = {
  participant_id: Scalars["String"];
  case_manager_id: Scalars["String"];
  label: Scalars["String"];
};

export type MutationDeleteParticipantNoteArgs = {
  participant_id: Scalars["String"];
  case_manager_id: Scalars["String"];
  participant_note_id: Scalars["String"];
};

export type MutationUpdateParticipantNoteArgs = {
  participant_id: Scalars["String"];
  case_manager_id: Scalars["String"];
  participant_note_id: Scalars["String"];
  label: Scalars["String"];
};

export type MutationReviewReviewItemArgs = {
  id: Scalars["String"];
  reviewed?: Maybe<Scalars["Boolean"]>;
  expire?: Maybe<Scalars["Boolean"]>;
  expire_immediately?: Maybe<Scalars["Boolean"]>;
};

export type MutationLogOutParticipantArgs = {
  device_id?: Maybe<Scalars["String"]>;
};

export type MutationChangeParticipantIsActiveStatusArgs = {
  participant_id: Scalars["String"];
  is_active: Scalars["Boolean"];
  reason?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  recidivism_date?: Maybe<Scalars["String"]>;
  recidivism_reason?: Maybe<RecidivismReason>;
};

export type MutationAddParticipantDocumentArgs = {
  participant_id: Scalars["String"];
  name: Scalars["String"];
  mime_type: Scalars["String"];
  filepath: Scalars["String"];
  case_manager_name?: Maybe<Scalars["String"]>;
};

export type MutationSoftDeleteParticipantDocumentArgs = {
  participant_id: Scalars["String"];
  document_id: Scalars["String"];
};

export type MutationAddAssessmentTemplateArgs = {
  id: Scalars["ID"];
  assessment_name: Scalars["String"];
  questions?: Maybe<Array<Maybe<AssessmentTemplateQuestionInput>>>;
  ranges?: Maybe<Array<Maybe<AssessmentTemplateRangesInput>>>;
};

export type MutationAddParticipantAssessmentResultArgs = {
  participant_id: Scalars["String"];
  assessment_template_id: Scalars["String"];
  scored_by: Scalars["String"];
  case_number: Scalars["String"];
};

export type MutationScoreParticipantAssessmentResultArgs = {
  participant_assessment_result_id: Scalars["String"];
  status: ParticipantAssessmentResultStatus;
  assessment_result_scores: Array<
    Maybe<ParticipantAssessmentResultScoresInput>
  >;
};

export type MutationAddCaseArgs = {
  participant_id: Scalars["String"];
  case_number: Scalars["String"];
};

export type MutationUpdateCaseArgs = {
  case_id: Scalars["String"];
  supervision_start_date?: Maybe<Scalars["String"]>;
  supervision_end_date?: Maybe<Scalars["String"]>;
  housing?: Maybe<NeedStatus>;
  employment?: Maybe<NeedStatus>;
  education?: Maybe<NeedStatus>;
  transportation?: Maybe<NeedStatus>;
  did_recidivate?: Maybe<YesNoUnknown>;
  treatment_types?: Maybe<Array<Maybe<IdInput>>>;
  case_type?: Maybe<Scalars["String"]>;
  medicated?: Maybe<YesNoUnknown>;
  program_result?: Maybe<Scalars["String"]>;
  courts?: Maybe<Array<Maybe<IdInput>>>;
  group_meetings?: Maybe<Array<Maybe<IdInput>>>;
  risk_level?: Maybe<Scalars["String"]>;
  assessment_score?: Maybe<Scalars["String"]>;
  primary_treatment_facilities?: Maybe<Array<Maybe<IdInput>>>;
  recidivism_reason?: Maybe<RecidivismReason>;
  hand_off_to_probation?: Maybe<YesNoUnknown>;
  absconded?: Maybe<YesNoUnknown>;
  is_emh?: Maybe<YesNoUnknown>;
  risk_score?: Maybe<Scalars["String"]>;
};

export type MutationAddGroupClassArgs = {
  group_class_id?: Maybe<Scalars["String"]>;
  start_date: Scalars["String"];
  start_time: Scalars["String"];
  end_date: Scalars["String"];
  days_of_week: Array<Maybe<Scalars["String"]>>;
  participant_ids: Array<Maybe<Scalars["String"]>>;
  activity_id: Scalars["String"];
  service_provider_id: Scalars["String"];
  exclude_dates?: Maybe<Array<Scalars["String"]>>;
};

export type MutationDeleteGroupClassArgs = {
  group_class_id: Scalars["String"];
};

export type MutationAddBookingArgs = {
  id: Scalars["String"];
  booking_defendent: BookingDefendentInput;
  booking_info: BookingInfoInput;
  booking_charges?: Maybe<Array<BookingChargeInput>>;
  booking_releases?: Maybe<Array<BookingReleaseInput>>;
  classification?: Maybe<BookingClassification>;
  booking_status?: Maybe<BookingStatusInput>;
  is_eligible?: Maybe<YesNoUnknown>;
};

export type MutationUpdateBookingClassificationArgs = {
  id: Scalars["String"];
  classification: BookingClassification;
};

export type MutationAddBookingGroupArgs = {
  id?: Maybe<Scalars["String"]>;
  bookings_to_modify: Array<Maybe<BookingToModifyInput>>;
};

export type MutationEmailTransmittalArgs = {
  booking_group_id: Scalars["String"];
};

export type MutationAddObligationsFromCarePlanItemsArgs = {
  participant_id: Scalars["String"];
  care_plan_items?: Maybe<Array<Maybe<CarePlanItem>>>;
};

export type MutationEditCheckInObligationCadenceSettingsArgs = {
  obligation_id: Scalars["String"];
  check_in_data: CheckInData;
};

export type MutationStartBookingInterviewAndAssessmentArgs = {
  booking_id: Scalars["ID"];
};

export type MutationDeclineBookingInterviewArgs = {
  booking_id: Scalars["ID"];
};

export type MutationUpdateBookingInterviewAndAssessmentArgs = {
  booking_id: Scalars["ID"];
  research_factors: ResearchFactorInput;
  risk_factors: RiskFactorInput;
  court_date?: Maybe<Scalars["String"]>;
  charge_bond_type_amount_court?: Maybe<Scalars["String"]>;
};

export type MutationSubmitBookingInterviewAndAssessmentArgs = {
  booking_id: Scalars["ID"];
  research_factors: ResearchFactorInput;
  risk_factors: RiskFactorInput;
  court_date?: Maybe<Scalars["String"]>;
  charge_bond_type_amount_court?: Maybe<Scalars["String"]>;
};

export type MutationSubmitBookingCourtMemoArgs = {
  booking_id: Scalars["ID"];
  court_memo: CourtMemoInput;
};

export type MutationUpdateBookingInterviewDataArgs = {
  booking_id: Scalars["ID"];
  interview_data: InterviewDataInput;
};

export type MutationUpdateBookingRecommendationFormArgs = {
  booking_id: Scalars["ID"];
  recommendation_form: RecommendationFormInput;
};

export type MutationSubmitBookingRecommendationFormForReviewArgs = {
  booking_id: Scalars["ID"];
  recommendation_form: RecommendationFormInput;
};

export type MutationReviewBookingRecommendationFormArgs = {
  booking_id: Scalars["ID"];
  recommendation_form: RecommendationFormInput;
  is_approved: Scalars["Boolean"];
};

export type MutationAddNoteToBookingRecommendationFormArgs = {
  booking_id: Scalars["ID"];
  text: Scalars["String"];
};

export type MutationSubmitBookingRecommendationFormArgs = {
  booking_id: Scalars["ID"];
};

export type MutationRevokeBookingRecommendationApprovalArgs = {
  booking_id: Scalars["ID"];
};

export type MutationUpdateBookingInfoDataArgs = {
  booking_id: Scalars["ID"];
  booking_info_update_data: BookingInfoUpdateDataInput;
};

export type MutationUpdateBookingCourtDecisionDataArgs = {
  booking_id: Scalars["ID"];
  pretrial_decision_form_data: PretrialDecisionFormInput;
};

export type MutationArchiveBookingArgs = {
  booking_id: Scalars["ID"];
};

export type MutationCreateParticipantFromBookingArgs = {
  booking_id: Scalars["ID"];
};

export type MutationAddBookingReleaseArgs = {
  booking_id: Scalars["ID"];
  release_type?: Maybe<BookingReleaseType>;
};

export interface MutationResponse {
  __typename?: "MutationResponse";
  result: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  ids?: Maybe<Array<Maybe<Scalars["String"]>>>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export enum MutationResult {
  Success = "success",
  Failure = "failure",
  Skipped = "skipped"
}

export enum NeedStatus {
  Has = "has",
  Needs = "needs",
  Obtained = "obtained",
  Unknown = "unknown"
}

export interface NewAssessmentTemplate {
  __typename?: "NewAssessmentTemplate";
  assessment_template?: Maybe<AssessmentTemplate>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface NewBookingGroupResult {
  __typename?: "NewBookingGroupResult";
  booking_group?: Maybe<BookingGroup>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface NewBookingResult {
  __typename?: "NewBookingResult";
  booking?: Maybe<Booking>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface NewBroadcastMessage {
  __typename?: "NewBroadcastMessage";
  broadcast_message?: Maybe<BroadcastMessage>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface NewCaseResult {
  __typename?: "NewCaseResult";
  case?: Maybe<Case>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface NewEvent {
  __typename?: "NewEvent";
  event_template?: Maybe<EventTemplate>;
  event?: Maybe<Event>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
}

export interface NewGroupClassResult {
  __typename?: "NewGroupClassResult";
  group_class?: Maybe<GroupClass>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface NewMessage {
  __typename?: "NewMessage";
  newMessage?: Maybe<Message>;
  newMessageCount?: Maybe<Scalars["Int"]>;
  newMessageIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface NewObligation {
  __typename?: "NewObligation";
  result: MutationResult;
  obligation?: Maybe<Obligation>;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface NewParticipant {
  __typename?: "NewParticipant";
  participant?: Maybe<Participant>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface NewParticipantAssessmentResult {
  __typename?: "NewParticipantAssessmentResult";
  participant_assessment_result?: Maybe<ParticipantAssessmentResult>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface NewParticipantDocument {
  __typename?: "NewParticipantDocument";
  participant?: Maybe<Participant>;
  document?: Maybe<ParticipantDocument>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface NewParticipantGoal {
  __typename?: "NewParticipantGoal";
  participant?: Maybe<Participant>;
  goal?: Maybe<ParticipantGoal>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface NewParticipantTask {
  __typename?: "NewParticipantTask";
  participant?: Maybe<Participant>;
  task?: Maybe<ParticipantTask>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
}

export interface Notification {
  __typename?: "Notification";
  action?: Maybe<Scalars["String"]>;
  body?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["String"]>;
  event?: Maybe<Event>;
  task?: Maybe<ParticipantTask>;
  id?: Maybe<Scalars["ID"]>;
  participant?: Maybe<Participant>;
  participant_id?: Maybe<Scalars["String"]>;
  time_acknowledged?: Maybe<Scalars["String"]>;
  time_delivered?: Maybe<Scalars["String"]>;
  time_deployed?: Maybe<Scalars["String"]>;
  time_to_deploy?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  final_delivery_status?: Maybe<Scalars["String"]>;
  final_delivery_error_code?: Maybe<Scalars["String"]>;
  final_delivery_error_message?: Maybe<Scalars["String"]>;
  delivery_status_verified?: Maybe<Scalars["Boolean"]>;
  twilio_message_id?: Maybe<Scalars["String"]>;
  fs_path: Scalars["String"];
  type?: Maybe<Scalars["String"]>;
  retries?: Maybe<Scalars["Int"]>;
}

export interface Obligation {
  __typename?: "Obligation";
  id?: Maybe<Scalars["ID"]>;
  activity?: Maybe<Activity>;
  participant?: Maybe<Participant>;
  service_provider?: Maybe<ServiceProvider>;
  event_templates?: Maybe<Array<Maybe<EventTemplate>>>;
  title?: Maybe<I18nText>;
  description?: Maybe<I18nText>;
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  compliance?: Maybe<Scalars["String"]>;
  sub_address_for_event?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  is_writable?: Maybe<Scalars["Boolean"]>;
  next_input_event?: Maybe<Event>;
  last_input_event?: Maybe<Event>;
  tag?: Maybe<Scalars["String"]>;
  group_class?: Maybe<GroupClass>;
  cadence_option?: Maybe<CadenceOptions>;
  day_of_week?: Maybe<Scalars["Int"]>;
  day_of_month?: Maybe<Scalars["Int"]>;
  verification_option?: Maybe<VerificationOptions>;
}

export interface ObligationData {
  eventDate?: Maybe<Scalars["String"]>;
  eventTime?: Maybe<Scalars["String"]>;
  roomNumber?: Maybe<Scalars["String"]>;
}

export interface ObligationEventInput {
  date?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
}

export interface ObligationEventPayload {
  id: Scalars["ID"];
  obligation_ref: Scalars["String"];
  date?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  attended?: Maybe<EventAttendence>;
  time_specified?: Maybe<Scalars["Boolean"]>;
  disposition?: Maybe<Scalars["String"]>;
  should_delete?: Maybe<Scalars["Boolean"]>;
}

export interface ObligationPayload {
  id: Scalars["ID"];
  activity_ref?: Maybe<Scalars["String"]>;
  participant_ref?: Maybe<Scalars["String"]>;
  service_provider_ref?: Maybe<Scalars["String"]>;
  description_en?: Maybe<Scalars["String"]>;
  title_en?: Maybe<Scalars["String"]>;
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  compliance?: Maybe<Scalars["String"]>;
  sub_address_for_event?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  group_class_id?: Maybe<Scalars["String"]>;
  cadence_option?: Maybe<Scalars["String"]>;
  day_of_week?: Maybe<Scalars["Int"]>;
  day_of_month?: Maybe<Scalars["Int"]>;
  verification_option?: Maybe<Scalars["String"]>;
  verification_obligation_ref?: Maybe<Scalars["String"]>;
  events?: Maybe<Array<Maybe<ObligationEventInput>>>;
}

export interface Pagination {
  __typename?: "Pagination";
  activity?: Maybe<Array<Maybe<Activity>>>;
  broadcast_message?: Maybe<Array<Maybe<BroadcastMessage>>>;
  cursor?: Maybe<Scalars["String"]>;
  log?: Maybe<Array<Maybe<LogEntry>>>;
  message?: Maybe<Array<Maybe<Message>>>;
  notification?: Maybe<Array<Maybe<Notification>>>;
  page_info?: Maybe<PaginationInfo>;
  obligation?: Maybe<Array<Maybe<Obligation>>>;
  participant?: Maybe<Array<Maybe<Participant>>>;
  review_item?: Maybe<Array<Maybe<ReviewItem>>>;
  reminder?: Maybe<Array<Maybe<ReminderStyle>>>;
  timeline_entry?: Maybe<Array<Maybe<TimelineEntry>>>;
  case_manager?: Maybe<Array<Maybe<CaseManager>>>;
  service_provider?: Maybe<Array<Maybe<ServiceProvider>>>;
  integration?: Maybe<Array<Maybe<Integration>>>;
  person_group?: Maybe<Array<Maybe<PersonGroup>>>;
  person_group_membership?: Maybe<Array<Maybe<PersonGroupMembership>>>;
  booking?: Maybe<Array<Maybe<Booking>>>;
  booking_group?: Maybe<Array<Maybe<BookingGroup>>>;
}

export interface PaginationInfo {
  __typename?: "PaginationInfo";
  has_next_page?: Maybe<Scalars["Boolean"]>;
  page_size?: Maybe<Scalars["Int"]>;
}

export interface Participant {
  __typename?: "Participant";
  id: Scalars["ID"];
  name: PersonName;
  participant_id?: Maybe<Scalars["String"]>;
  created_at: Scalars["String"];
  language: Scalars["String"];
  notify_via?: Maybe<Scalars["String"]>;
  phone?: Maybe<PersonPhone>;
  events?: Maybe<Array<Maybe<Event>>>;
  activities?: Maybe<Array<Maybe<ParticipantActivity>>>;
  received_message_unread_count?: Maybe<Scalars["Int"]>;
  sent_message_unread_count?: Maybe<Scalars["Int"]>;
  auth_token?: Maybe<Scalars["String"]>;
  fs_path: Scalars["String"];
  risk_level?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  compliance?: Maybe<Scalars["String"]>;
  case_manager?: Maybe<CaseManager>;
  secondary_case_manager?: Maybe<CaseManager>;
  coordinator_contact?: Maybe<ParticipantCoordinatorContact>;
  global_registered_phones?: Maybe<Array<Maybe<Scalars["String"]>>>;
  devices?: Maybe<Array<Maybe<ParticipantMobileDevice>>>;
  current_device?: Maybe<ParticipantMobileDevice>;
  assessment?: Maybe<ParticipantAssessment>;
  case_manager_id?: Maybe<Scalars["String"]>;
  obligations?: Maybe<Array<Maybe<Obligation>>>;
  tasks?: Maybe<Array<Maybe<ParticipantTask>>>;
  goals?: Maybe<Array<Maybe<ParticipantGoal>>>;
  notes?: Maybe<Array<Maybe<ParticipantNote>>>;
  messages?: Maybe<Pagination>;
  most_recent_message_timestamp?: Maybe<Scalars["String"]>;
  sms_enabled?: Maybe<Scalars["Boolean"]>;
  sms_disabled_by?: Maybe<SmsMessageActor>;
  sms_consent?: Maybe<Scalars["Boolean"]>;
  sms_can_enable?: Maybe<Scalars["Boolean"]>;
  is_active?: Maybe<Scalars["Boolean"]>;
  date_of_birth?: Maybe<Scalars["String"]>;
  race?: Maybe<PersonRace>;
  hispanic_or_latino?: Maybe<Scalars["Boolean"]>;
  preferred_name?: Maybe<Scalars["String"]>;
  program_title?: Maybe<Scalars["String"]>;
  supervision_begin_date?: Maybe<Scalars["String"]>;
  supervision_end_date?: Maybe<Scalars["String"]>;
  program_status?: Maybe<ProgramStatus>;
  did_recidivate?: Maybe<Scalars["Boolean"]>;
  recidivism_events?: Maybe<Array<Maybe<RecidivismEvent>>>;
  incarceration_status?: Maybe<IncarcerationStatus>;
  deactivated_reason?: Maybe<DeactivatedReason>;
  reactivated_reason?: Maybe<ReactivatedReason>;
  address?: Maybe<VerifiedAddress>;
  employment?: Maybe<VerifiedEmployment>;
  law_enforcement_contact?: Maybe<VerifiedLawEnforcementContact>;
  aa_meeting?: Maybe<VerifiedAaMeeting>;
  is_test_user?: Maybe<Scalars["Boolean"]>;
  phase?: Maybe<Scalars["String"]>;
  points?: Maybe<Scalars["Int"]>;
  alcohol_monitoring?: Maybe<Scalars["Boolean"]>;
  gps_monitoring?: Maybe<Scalars["Boolean"]>;
  sex?: Maybe<PersonSex>;
  employer?: Maybe<ParticipantEmployer>;
  eye_color?: Maybe<PersonEyeColor>;
  hair?: Maybe<PersonHair>;
  height?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["String"]>;
  person_address?: Maybe<Scalars["String"]>;
  place_of_birth?: Maybe<Scalars["String"]>;
  person_id?: Maybe<PersonId>;
  stay_away?: Maybe<Scalars["Boolean"]>;
  peaceful_contact?: Maybe<Scalars["Boolean"]>;
  case_number?: Maybe<Scalars["String"]>;
  case_numbers?: Maybe<Array<Maybe<Scalars["String"]>>>;
  extra_equipment?: Maybe<Scalars["String"]>;
  incident_notes?: Maybe<Scalars["String"]>;
  offense_info?: Maybe<Scalars["String"]>;
  data_update_needed?: Maybe<Scalars["Boolean"]>;
  profile_image?: Maybe<PersonImage>;
  fines_and_fees?: Maybe<FinesAndFees>;
  documents?: Maybe<Array<Maybe<ParticipantDocument>>>;
  record_source?: Maybe<Scalars["String"]>;
  court_recommendation?: Maybe<Scalars["String"]>;
  court_decision?: Maybe<Scalars["String"]>;
  case_disposition?: Maybe<Scalars["String"]>;
  home_phone_human_readable?: Maybe<Scalars["String"]>;
  cell_phone_human_readable?: Maybe<Scalars["String"]>;
  assessment_results?: Maybe<Array<Maybe<ParticipantAssessmentResult>>>;
  cases?: Maybe<Array<Maybe<Case>>>;
  victims?: Maybe<Array<ParticipantContact>>;
  equipment_returned?: Maybe<Array<Maybe<Scalars["String"]>>>;
  protective_order_notes?: Maybe<Scalars["String"]>;
  probation_number?: Maybe<Scalars["String"]>;
}

export type ParticipantEventsArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
};

export type ParticipantReceived_Message_Unread_CountArgs = {
  unread_for: Scalars["String"];
};

export type ParticipantSent_Message_Unread_CountArgs = {
  unread_for: Scalars["String"];
};

export type ParticipantMessagesArgs = {
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["String"]>;
};

export interface ParticipantActivity {
  __typename?: "ParticipantActivity";
  date_range?: Maybe<DateRange>;
  status?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  activity?: Maybe<Activity>;
}

export interface ParticipantAssessment {
  __typename?: "ParticipantAssessment";
  score?: Maybe<Scalars["String"]>;
  tool?: Maybe<Scalars["String"]>;
}

export interface ParticipantAssessmentResult {
  __typename?: "ParticipantAssessmentResult";
  id: Scalars["ID"];
  case_number: Scalars["String"];
  participant_id: Scalars["String"];
  assessment_id: Scalars["String"];
  assessment_name: Scalars["String"];
  ranges: Array<Maybe<AssessmentTemplateRange>>;
  scores: Array<Maybe<ParticipantAssessmentResultScore>>;
  total_score?: Maybe<Scalars["Int"]>;
  rating?: Maybe<Scalars["String"]>;
  created_at: Scalars["String"];
  scored_by: Scalars["String"];
  updated_at?: Maybe<Scalars["String"]>;
  status: ParticipantAssessmentResultStatus;
}

export interface ParticipantAssessmentResultScore {
  __typename?: "ParticipantAssessmentResultScore";
  id: Scalars["ID"];
  assessment_template_question_id: Scalars["String"];
  score?: Maybe<Scalars["Int"]>;
  value?: Maybe<Scalars["String"]>;
  verified?: Maybe<Scalars["Boolean"]>;
  question_text: Scalars["String"];
  question_number: Scalars["Float"];
  question_options: Array<Maybe<AssessmentTemplateQuestionOption>>;
}

export interface ParticipantAssessmentResultScoresInput {
  value: Scalars["String"];
  score: Scalars["Int"];
  verified: Scalars["Boolean"];
  id: Scalars["String"];
}

export enum ParticipantAssessmentResultStatus {
  New = "new",
  Draft = "draft",
  Finalized = "finalized"
}

export interface ParticipantContact {
  __typename?: "ParticipantContact";
  name?: Maybe<VictimName>;
  address?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  date_of_birth?: Maybe<Scalars["String"]>;
  sex?: Maybe<PersonSex>;
  relationship?: Maybe<Scalars["String"]>;
  how_often_seen?: Maybe<Scalars["String"]>;
}

export interface ParticipantContactInput {
  name?: Maybe<VictimNameInput>;
  address?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  date_of_birth?: Maybe<Scalars["String"]>;
  sex?: Maybe<PersonSex>;
  relationship?: Maybe<Scalars["String"]>;
  how_often_seen?: Maybe<Scalars["String"]>;
}

export interface ParticipantCoordinatorContact {
  __typename?: "ParticipantCoordinatorContact";
  id?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  phone: Scalars["String"];
}

export interface ParticipantDocument {
  __typename?: "ParticipantDocument";
  id: Scalars["ID"];
  created_at: Scalars["String"];
  mime_type: Scalars["String"];
  name: Scalars["String"];
  filepath: Scalars["String"];
  case_manager_name?: Maybe<Scalars["String"]>;
  soft_deleted?: Maybe<Scalars["Boolean"]>;
  soft_deleted_on?: Maybe<Scalars["String"]>;
}

export interface ParticipantEmployer {
  __typename?: "ParticipantEmployer";
  name?: Maybe<Scalars["String"]>;
  address?: Maybe<Scalars["String"]>;
}

export interface ParticipantGoal {
  __typename?: "ParticipantGoal";
  id: Scalars["ID"];
  label: Scalars["String"];
  completed_at?: Maybe<Scalars["String"]>;
  created_at: Scalars["String"];
}

export interface ParticipantMobileDevice {
  __typename?: "ParticipantMobileDevice";
  app_version?: Maybe<Scalars["String"]>;
  device_id?: Maybe<Scalars["String"]>;
  device_name?: Maybe<Scalars["String"]>;
  last_app_launch?: Maybe<Scalars["String"]>;
  platform?: Maybe<Scalars["String"]>;
  token?: Maybe<Scalars["String"]>;
}

export interface ParticipantNote {
  __typename?: "ParticipantNote";
  id: Scalars["ID"];
  label: Scalars["String"];
  created_at: Scalars["String"];
  updated_at?: Maybe<Scalars["String"]>;
  case_manager?: Maybe<CaseManager>;
}

export interface ParticipantPayload {
  id: Scalars["String"];
  first: Scalars["String"];
  last?: Maybe<Scalars["String"]>;
  middle?: Maybe<Scalars["String"]>;
  mobile?: Maybe<Scalars["String"]>;
  home_phone?: Maybe<Scalars["String"]>;
  language?: Maybe<Scalars["String"]>;
  assessment_score?: Maybe<Scalars["String"]>;
  assessment_tool?: Maybe<Scalars["String"]>;
  risk_level?: Maybe<Scalars["String"]>;
  sms_enabled?: Maybe<Scalars["Boolean"]>;
  sms_disabled_by?: Maybe<SmsMessageActor>;
  sms_consent?: Maybe<Scalars["Boolean"]>;
  sms_can_enable?: Maybe<Scalars["Boolean"]>;
  is_active?: Maybe<Scalars["Boolean"]>;
  date_of_birth?: Maybe<Scalars["String"]>;
  race?: Maybe<PersonRace>;
  hispanic_or_latino?: Maybe<Scalars["Boolean"]>;
  participant_id?: Maybe<Scalars["String"]>;
  preferred_name?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  compliance?: Maybe<Scalars["String"]>;
  supervision_begin_date?: Maybe<Scalars["String"]>;
  supervision_end_date?: Maybe<Scalars["String"]>;
  program_status?: Maybe<ProgramStatus>;
  recidivism_date?: Maybe<Scalars["String"]>;
  recidivism_reason?: Maybe<RecidivismReason>;
  incarceration_status?: Maybe<IncarcerationStatus>;
  deactivated_reason?: Maybe<DeactivatedReason>;
  address?: Maybe<Scalars["String"]>;
  employment?: Maybe<Scalars["String"]>;
  law_enforcement_contact?: Maybe<YesNo>;
  aa_meeting?: Maybe<YesNo>;
  phase?: Maybe<Scalars["String"]>;
  points?: Maybe<Scalars["Int"]>;
  alcohol_monitoring?: Maybe<Scalars["Boolean"]>;
  gps_monitoring?: Maybe<Scalars["Boolean"]>;
  sex?: Maybe<PersonSex>;
  employer_name?: Maybe<Scalars["String"]>;
  eye_color?: Maybe<PersonEyeColor>;
  hair?: Maybe<PersonHair>;
  height?: Maybe<Scalars["String"]>;
  weight?: Maybe<Scalars["String"]>;
  person_address?: Maybe<Scalars["String"]>;
  place_of_birth?: Maybe<Scalars["String"]>;
  id_number?: Maybe<Scalars["String"]>;
  id_type?: Maybe<IdCardType>;
  id_state?: Maybe<Scalars["String"]>;
  victims?: Maybe<Array<Maybe<ParticipantVictimInput>>>;
  stay_away?: Maybe<Scalars["Boolean"]>;
  peaceful_contact?: Maybe<Scalars["Boolean"]>;
  case_number?: Maybe<Scalars["String"]>;
  extra_equipment?: Maybe<Scalars["String"]>;
  incident_notes?: Maybe<Scalars["String"]>;
  offense_info?: Maybe<Scalars["String"]>;
  base64_media?: Maybe<Scalars["String"]>;
  mime_type?: Maybe<Scalars["String"]>;
  fines_and_fees_amount_owed?: Maybe<Scalars["Int"]>;
  fines_and_fees_last_payment_amount?: Maybe<Scalars["Int"]>;
  fines_and_fees_last_payment_date?: Maybe<Scalars["String"]>;
  fines_and_fees_expected_payment_amount?: Maybe<Scalars["Int"]>;
  restitution_amount_owed?: Maybe<Scalars["Int"]>;
  restitution_last_payment_amount?: Maybe<Scalars["Int"]>;
  restitution_last_payment_date?: Maybe<Scalars["String"]>;
  restitution_expected_payment_amount?: Maybe<Scalars["Int"]>;
  record_source?: Maybe<Scalars["String"]>;
  court_recommendation?: Maybe<Scalars["String"]>;
  court_decision?: Maybe<Scalars["String"]>;
  case_disposition?: Maybe<Scalars["String"]>;
  equipment_returned?: Maybe<Array<Maybe<Scalars["String"]>>>;
  protective_order_notes?: Maybe<Scalars["String"]>;
  probation_number?: Maybe<Scalars["String"]>;
  case_manager_ref?: Maybe<Scalars["String"]>;
  secondary_case_manager_ref?: Maybe<Scalars["String"]>;
}

export interface ParticipantTask {
  __typename?: "ParticipantTask";
  id: Scalars["ID"];
  created_at: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  completed_at?: Maybe<Scalars["String"]>;
  due_at?: Maybe<Scalars["String"]>;
  fs_path: Scalars["String"];
}

export interface ParticipantVictimInput {
  name?: Maybe<VictimNameInput>;
  address?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  date_of_birth?: Maybe<Scalars["String"]>;
  sex?: Maybe<PersonSex>;
}

export enum PersonEyeColor {
  Black = "black",
  Blue = "blue",
  Brown = "brown",
  Gray = "gray",
  Green = "green",
  Hazel = "hazel",
  Maroon = "maroon",
  Unknown = "unknown"
}

export interface PersonGroup {
  __typename?: "PersonGroup";
  id: Scalars["ID"];
  label: Scalars["String"];
  created_at?: Maybe<Scalars["String"]>;
  member_count?: Maybe<Scalars["Int"]>;
  unread_message_count?: Maybe<Scalars["Int"]>;
}

export interface PersonGroupMembership {
  __typename?: "PersonGroupMembership";
  id: Scalars["ID"];
  group_label: Scalars["String"];
  group_id: Scalars["String"];
  joined_at?: Maybe<Scalars["String"]>;
  participant_id: Scalars["String"];
  case_manager_id: Scalars["String"];
  member_type: Scalars["String"];
  name?: Maybe<PersonName>;
}

export enum PersonHair {
  Bald = "bald",
  Black = "black",
  Blonde = "blonde",
  Blue = "blue",
  Brown = "brown",
  Green = "green",
  Gray = "gray",
  Orange = "orange",
  Purple = "purple",
  Pink = "pink",
  Red = "red",
  Sandy = "sandy",
  White = "white",
  Unknown = "unknown"
}

export interface PersonId {
  __typename?: "PersonId";
  number?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  type?: Maybe<IdCardType>;
}

export interface PersonImage {
  __typename?: "PersonImage";
  base64_media?: Maybe<Scalars["String"]>;
  mime_type?: Maybe<Scalars["String"]>;
}

export interface PersonName {
  __typename?: "PersonName";
  first: Scalars["String"];
  last?: Maybe<Scalars["String"]>;
  middle?: Maybe<Scalars["String"]>;
}

export interface PersonNameInput {
  first: Scalars["String"];
  last?: Maybe<Scalars["String"]>;
  middle?: Maybe<Scalars["String"]>;
}

export interface PersonPhone {
  __typename?: "PersonPhone";
  mobile?: Maybe<Scalars["String"]>;
  home?: Maybe<Scalars["String"]>;
}

export enum PersonRace {
  AmericanIndianOrAlaskaNative = "AmericanIndianOrAlaskaNative",
  Asian = "Asian",
  BlackOrAfricanAmerican = "BlackOrAfricanAmerican",
  NativeHawaiianOrOtherPacificIslander = "NativeHawaiianOrOtherPacificIslander",
  White = "White",
  Unknown = "Unknown"
}

export enum PersonSex {
  Male = "male",
  Female = "female",
  Unknown = "unknown"
}

export enum PretrialDecision {
  OrGranted = "or_granted",
  OrDenied = "or_denied",
  PretrialReleaseWithConditions = "pretrial_release_with_conditions",
  ReleasedNoComplaintFiled = "released_no_complaint_filed",
  BailedOut = "bailed_out",
  CaseDismissed = "case_dismissed",
  Sentenced = "sentenced",
  PetitionResolved = "petition_resolved"
}

export interface PretrialDecisionForm {
  __typename?: "PretrialDecisionForm";
  decision?: Maybe<PretrialDecision>;
  has_gps_monitoring?: Maybe<YesNo>;
  has_alcohol_monitoring?: Maybe<YesNo>;
  has_basic_monitoring?: Maybe<YesNo>;
  sentencing_decision?: Maybe<SentenceDecision>;
  jail_sanction?: Maybe<YesNo>;
}

export interface PretrialDecisionFormInput {
  decision?: Maybe<PretrialDecision>;
  has_gps_monitoring?: Maybe<YesNo>;
  has_alcohol_monitoring?: Maybe<YesNo>;
  has_basic_monitoring?: Maybe<YesNo>;
  sentencing_decision?: Maybe<SentenceDecision>;
  jail_sanction?: Maybe<YesNo>;
}

export interface PriorRecordInfo {
  __typename?: "PriorRecordInfo";
  bench_warrants_count?: Maybe<Scalars["Int"]>;
  currently_on_probation?: Maybe<YesNo>;
  has_pending_case?: Maybe<YesNo>;
  prior_record_comments?: Maybe<Scalars["String"]>;
}

export interface PriorRecordInfoInput {
  bench_warrants_count?: Maybe<Scalars["Int"]>;
  currently_on_probation?: Maybe<YesNo>;
  has_pending_case?: Maybe<YesNo>;
  prior_record_comments?: Maybe<Scalars["String"]>;
}

export enum ProgramStatus {
  Successful = "successful",
  Unsuccessful = "unsuccessful",
  DidNotComplete = "did_not_complete"
}

export interface ProspectNote {
  __typename?: "ProspectNote";
  text: Scalars["String"];
  created_at: Scalars["String"];
  created_by?: Maybe<CaseManager>;
}

export enum ProspectStatus {
  New = "new",
  PendingInterview = "pending_interview",
  PendingRecommendation = "pending_recommendation",
  PendingCourtDecision = "pending_court_decision",
  EnrollOrArchive = "enroll_or_archive",
  Archived = "archived"
}

export interface ProspectStatusCounts {
  __typename?: "ProspectStatusCounts";
  new: Scalars["Int"];
  pending_interview: Scalars["Int"];
  pending_recommendation: Scalars["Int"];
  pending_court_decision: Scalars["Int"];
  enroll_or_archive: Scalars["Int"];
  archived: Scalars["Int"];
}

export interface Query {
  __typename?: "Query";
  application?: Maybe<Application>;
  activities?: Maybe<Pagination>;
  broadcast_messages?: Maybe<Pagination>;
  logs?: Maybe<Pagination>;
  me?: Maybe<Me>;
  messages?: Maybe<Pagination>;
  most_recent_message_timestamp?: Maybe<Scalars["String"]>;
  sent_message_unread_count?: Maybe<Scalars["Int"]>;
  notifications?: Maybe<Pagination>;
  obligations?: Maybe<Pagination>;
  participants?: Maybe<Pagination>;
  person_groups?: Maybe<Pagination>;
  person_group_memberships?: Maybe<Pagination>;
  review_items?: Maybe<Pagination>;
  case_managers?: Maybe<Pagination>;
  bookings?: Maybe<Pagination>;
  booking_groups?: Maybe<Pagination>;
  service_providers?: Maybe<Pagination>;
  reminders?: Maybe<Pagination>;
  stats?: Maybe<Stats>;
  stats_report?: Maybe<StatsReport>;
  timeline?: Maybe<Pagination>;
  integrations?: Maybe<Pagination>;
  case_stats: Array<CaseStat>;
  group_classes?: Maybe<Array<Maybe<GroupClass>>>;
  booking_prospect_status_counts: ProspectStatusCounts;
}

export type QueryActivitiesArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
};

export type QueryBroadcast_MessagesArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
  order_by?: Maybe<Scalars["String"]>;
};

export type QueryLogsArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  participant?: Maybe<Scalars["String"]>;
  log_type?: Maybe<LogEntryType>;
  log_category?: Maybe<Scalars["String"]>;
};

export type QueryMessagesArgs = {
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
  participant_id?: Maybe<Scalars["String"]>;
  case_manager_id?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["String"]>;
};

export type QueryMost_Recent_Message_TimestampArgs = {
  participant_id?: Maybe<Scalars["String"]>;
  group_id?: Maybe<Scalars["String"]>;
};

export type QuerySent_Message_Unread_CountArgs = {
  participant_id: Scalars["String"];
  unread_for: Scalars["String"];
};

export type QueryNotificationsArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  time_type?: Maybe<Scalars["String"]>;
  direction?: Maybe<Scalars["String"]>;
  participant_id?: Maybe<Scalars["String"]>;
  event_id?: Maybe<Scalars["String"]>;
  task_id?: Maybe<Scalars["String"]>;
  delivery_status_verified?: Maybe<Scalars["Boolean"]>;
  delivered?: Maybe<Scalars["Boolean"]>;
  has_twilio_message_id?: Maybe<Scalars["Boolean"]>;
  undeployed?: Maybe<Scalars["Boolean"]>;
  needs_acknowledgement?: Maybe<Scalars["Boolean"]>;
};

export type QueryObligationsArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
};

export type QueryParticipantsArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
  auth_token?: Maybe<Scalars["String"]>;
  risk?: Maybe<Scalars["String"]>;
  bucket?: Maybe<BucketId>;
  name_contains?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  participant_id?: Maybe<Scalars["String"]>;
  participant_ids?: Maybe<Scalars["String"]>;
  case_manager_id?: Maybe<Scalars["String"]>;
  sub_query_only?: Maybe<Scalars["Boolean"]>;
  sms_enabled?: Maybe<Scalars["Boolean"]>;
  active_clients_only?: Maybe<Scalars["Boolean"]>;
  non_test_participant_only?: Maybe<Scalars["Boolean"]>;
  secondary_case_manager_id?: Maybe<Scalars["String"]>;
};

export type QueryPerson_GroupsArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
};

export type QueryPerson_Group_MembershipsArgs = {
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
};

export type QueryReview_ItemsArgs = {
  review_type: BucketId;
  case_manager_id?: Maybe<Scalars["String"]>;
};

export type QueryCase_ManagersArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
};

export type QueryBookingsArgs = {
  is_eligible?: Maybe<YesNoUnknown>;
  date_of_birth?: Maybe<Scalars["String"]>;
  prospect_status?: Maybe<ProspectStatus>;
  first_name?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
};

export type QueryBooking_GroupsArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
};

export type QueryService_ProvidersArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
};

export type QueryRemindersArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
};

export type QueryStatsArgs = {
  summarizeBy?: Maybe<SummaryType>;
  includePrevious?: Maybe<Scalars["Boolean"]>;
  latestOnly?: Maybe<Scalars["Boolean"]>;
};

export type QueryStats_ReportArgs = {
  week?: Maybe<Scalars["Int"]>;
  month?: Maybe<Scalars["Int"]>;
  year?: Maybe<Scalars["Int"]>;
};

export type QueryTimelineArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  participant?: Maybe<Scalars["String"]>;
  type?: Maybe<LogEntryType>;
};

export type QueryIntegrationsArgs = {
  id?: Maybe<Scalars["String"]>;
  ids?: Maybe<Scalars["String"]>;
  page_size?: Maybe<Scalars["Int"]>;
  page_cursor?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type QueryCase_StatsArgs = {
  risk_levels?: Maybe<Array<Maybe<Scalars["String"]>>>;
  courts?: Maybe<Array<Maybe<Scalars["String"]>>>;
  housing_statuses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  employment_statuses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  education_statuses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  transportation_statuses?: Maybe<Array<Maybe<Scalars["String"]>>>;
  primary_treatment_facilities?: Maybe<Array<Maybe<Scalars["String"]>>>;
  treatment_types?: Maybe<Array<Maybe<Scalars["String"]>>>;
  group_meetings?: Maybe<Array<Maybe<Scalars["String"]>>>;
  medicated?: Maybe<Array<Maybe<Scalars["String"]>>>;
  case_type?: Maybe<Array<Maybe<Scalars["String"]>>>;
  hand_off_to_probation?: Maybe<Array<Maybe<Scalars["String"]>>>;
  weeks_in_program?: Maybe<Array<Maybe<MinMaxInput>>>;
  absconded?: Maybe<Array<Maybe<Scalars["String"]>>>;
  is_emh?: Maybe<Array<Maybe<Scalars["String"]>>>;
  gender?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export enum ReactivatedReason {
  Release = "release",
  TransferredInAlternative = "transferred_in_alternative",
  TransferredInAnotherCounty = "transferred_in_another_county",
  TransferredInTreatment = "transferred_in_treatment",
  ReactivatedFromTemporarySuspension = "reactivated_from_temporary_suspension",
  ReactivatedNewCase = "reactivated_new_case",
  Other = "other"
}

export interface ReasonsAgainstRelease {
  __typename?: "ReasonsAgainstRelease";
  high_risk_to_fail_pretrial_release?: Maybe<YesNo>;
  vprai_override_public_safety?: Maybe<YesNo>;
  vprai_override_victim_residence?: Maybe<YesNo>;
}

export interface ReasonsAgainstReleaseInput {
  high_risk_to_fail_pretrial_release?: Maybe<YesNo>;
  vprai_override_public_safety?: Maybe<YesNo>;
  vprai_override_victim_residence?: Maybe<YesNo>;
}

export interface RecidivismEvent {
  __typename?: "RecidivismEvent";
  date?: Maybe<Scalars["String"]>;
  reason?: Maybe<RecidivismReason>;
}

export enum RecidivismReason {
  NewCharge = "new_charge",
  Civil = "civil",
  Unknown = "unknown"
}

export interface RecommendationForm {
  __typename?: "RecommendationForm";
  has_verified_employment_information?: Maybe<YesNo>;
  pending_disposition?: Maybe<YesNo>;
  pending_disposition_comments?: Maybe<Scalars["String"]>;
  vprai_override_public_safety_comments?: Maybe<Scalars["String"]>;
  incident_summary_notes?: Maybe<Scalars["String"]>;
  mandatory_detention?: Maybe<YesNo>;
  additional_information?: Maybe<Scalars["String"]>;
  release_recommendation?: Maybe<ReleaseRecommendation>;
  conditions_of_release: ConditionsOfRelease;
  reasons_against_release: ReasonsAgainstRelease;
  other_recommendation_notes?: Maybe<Scalars["String"]>;
  submitted_for_review_by?: Maybe<CaseManager>;
  submitted_for_review_at?: Maybe<Scalars["String"]>;
  reviewed_by?: Maybe<CaseManager>;
  reviewed_at?: Maybe<Scalars["String"]>;
  completed_by?: Maybe<CaseManager>;
  completed_at?: Maybe<Scalars["String"]>;
  internal_notes?: Maybe<Array<Maybe<ProspectNote>>>;
  criminal_history: CriminalHistory;
  afs_info: AfsInfo;
  substance_abuse_comments?: Maybe<Scalars["String"]>;
  victim_comments?: Maybe<Scalars["String"]>;
}

export interface RecommendationFormInput {
  has_verified_employment_information?: Maybe<YesNo>;
  pending_disposition?: Maybe<YesNo>;
  pending_disposition_comments?: Maybe<Scalars["String"]>;
  vprai_override_public_safety_comments?: Maybe<Scalars["String"]>;
  incident_summary_notes?: Maybe<Scalars["String"]>;
  mandatory_detention?: Maybe<YesNo>;
  additional_information?: Maybe<Scalars["String"]>;
  release_recommendation?: Maybe<ReleaseRecommendation>;
  conditions_of_release: ConditionsOfReleaseInput;
  reasons_against_release: ReasonsAgainstReleaseInput;
  other_recommendation_notes?: Maybe<Scalars["String"]>;
  victim_comments?: Maybe<Scalars["String"]>;
  criminal_history?: Maybe<CriminalHistoryInput>;
  afs_info: AfsInfoInput;
  substance_abuse_comments?: Maybe<Scalars["String"]>;
}

export enum RecommendationReviewStatus {
  NotReadyForReview = "not_ready_for_review",
  PendingReview = "pending_review",
  Approved = "approved",
  Rejected = "rejected"
}

export enum ReleaseRecommendation {
  OrGranted = "or_granted",
  ReleaseWithConditions = "release_with_conditions",
  DenyRelease = "deny_release"
}

export interface ReminderStyle {
  __typename?: "ReminderStyle";
  id: Scalars["ID"];
  scheduled?: Maybe<Array<Maybe<ReminderStyleContent>>>;
  new?: Maybe<ReminderStyleContent>;
  updated?: Maybe<ReminderStyleContent>;
  deleted?: Maybe<ReminderStyleContent>;
}

export interface ReminderStyleContent {
  __typename?: "ReminderStyleContent";
  type: Scalars["String"];
  body: I18nText;
  time_to: Scalars["Int"];
  time_required: Scalars["Boolean"];
}

export interface ReminderTemplatePayload {
  id: Scalars["String"];
  templates?: Maybe<Array<Maybe<ReminderTemplatePayloadContent>>>;
}

export interface ReminderTemplatePayloadContent {
  type: Scalars["String"];
  body: I18nTextInput;
  time_to: Scalars["Int"];
  time_required: Scalars["Boolean"];
}

export interface ResearchFactor {
  __typename?: "ResearchFactor";
  has_prior_misdemeanor_conviction?: Maybe<YesNo>;
  has_prior_felony_conviction?: Maybe<YesNo>;
  prior_violent_convictions_count?: Maybe<ZeroToThreeOrMore>;
  prior_FTAs_past_two_years_count?: Maybe<ZeroToThreeOrMore>;
  has_prior_FTAs_older_than_two_years?: Maybe<YesNo>;
  has_prior_sentence_to_incarceration?: Maybe<YesNo>;
}

export interface ResearchFactorInput {
  has_prior_misdemeanor_conviction?: Maybe<YesNo>;
  has_prior_felony_conviction?: Maybe<YesNo>;
  prior_violent_convictions_count?: Maybe<ZeroToThreeOrMore>;
  prior_FTAs_past_two_years_count?: Maybe<ZeroToThreeOrMore>;
  has_prior_FTAs_older_than_two_years?: Maybe<YesNo>;
  has_prior_sentence_to_incarceration?: Maybe<YesNo>;
}

export interface ResidenceInfo {
  __typename?: "ResidenceInfo";
  address?: Maybe<Scalars["String"]>;
  moved_into_current_address_date?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  moved_to_bay_area_date?: Maybe<Scalars["String"]>;
}

export interface ResidenceInfoInput {
  address?: Maybe<Scalars["String"]>;
  moved_into_current_address_date?: Maybe<Scalars["String"]>;
  phone_number?: Maybe<Scalars["String"]>;
  moved_to_bay_area_date?: Maybe<Scalars["String"]>;
}

export interface ReviewItem {
  __typename?: "ReviewItem";
  id: Scalars["ID"];
  created_at?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["Object"]>;
  document_id?: Maybe<Scalars["String"]>;
  obligation?: Maybe<Obligation>;
  event?: Maybe<Event>;
  expired: Scalars["Boolean"];
  expires_at?: Maybe<Scalars["String"]>;
  participant?: Maybe<Participant>;
  reviewed: Scalars["Boolean"];
  reviewed_at?: Maybe<Scalars["String"]>;
  reviewed_by_id?: Maybe<Scalars["String"]>;
  reviewed_by_case_manager?: Maybe<CaseManager>;
  type: Scalars["String"];
  uid?: Maybe<Scalars["String"]>;
}

export interface ReviewReviewItem {
  __typename?: "ReviewReviewItem";
  review_item?: Maybe<ReviewItem>;
  result: MutationResult;
  description?: Maybe<Scalars["String"]>;
}

export interface RiskAssessmentResult {
  __typename?: "RiskAssessmentResult";
  score?: Maybe<Scalars["Int"]>;
  level?: Maybe<Scalars["String"]>;
  failure_rate?: Maybe<Scalars["String"]>;
}

export interface RiskFactor {
  __typename?: "RiskFactor";
  has_active_community_supervision?: Maybe<YesNo>;
  is_charge_felony_drug_theft_or_fraud?: Maybe<YesNo>;
  has_pending_charges?: Maybe<YesNo>;
  has_criminal_history?: Maybe<YesNo>;
  has_two_or_more_FTAs?: Maybe<YesNo>;
  has_two_or_more_violent_convictions?: Maybe<YesNo>;
  has_history_of_drug_abuse?: Maybe<YesNo>;
  employed_at_time_of_arrest?: Maybe<Employment>;
}

export interface RiskFactorInput {
  has_active_community_supervision?: Maybe<YesNo>;
  is_charge_felony_drug_theft_or_fraud?: Maybe<YesNo>;
  has_pending_charges?: Maybe<YesNo>;
  has_criminal_history?: Maybe<YesNo>;
  has_two_or_more_FTAs?: Maybe<YesNo>;
  has_two_or_more_violent_convictions?: Maybe<YesNo>;
  has_history_of_drug_abuse?: Maybe<YesNo>;
  employed_at_time_of_arrest?: Maybe<Employment>;
}

export enum SentenceDecision {
  Diversion = "diversion",
  RestitutionOnly = "restitution_only",
  ConditionalCase = "conditional_case",
  SupervisedCase = "supervised_case"
}

export interface ServiceProvider {
  __typename?: "ServiceProvider";
  id?: Maybe<Scalars["ID"]>;
  address?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  sub_address_for_event?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
}

export interface ServiceProviderPayload {
  id: Scalars["String"];
  address?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
}

export enum SmsMessageActor {
  Promise = "promise",
  Twilio = "twilio"
}

export interface StatEventCountItem {
  __typename?: "StatEventCountItem";
  totals?: Maybe<StatEventCountValues>;
  breakdowns?: Maybe<Array<Maybe<StatEventCountValues>>>;
  month_totals?: Maybe<StatEventCountValues>;
  month_breakdowns?: Maybe<Array<Maybe<StatEventCountValues>>>;
}

export interface StatEventCountValues {
  __typename?: "StatEventCountValues";
  title?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Float"]>;
  with_appearance?: Maybe<Scalars["Float"]>;
  attended?: Maybe<Scalars["Float"]>;
  avg_attended?: Maybe<Scalars["Float"]>;
  rescheduled?: Maybe<Scalars["Float"]>;
  not_attended?: Maybe<Scalars["Float"]>;
  no_data?: Maybe<Scalars["Float"]>;
  future_event?: Maybe<Scalars["Float"]>;
}

export interface StatEventListItem {
  __typename?: "StatEventListItem";
  attended?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
  participant?: Maybe<StatItemParticipant>;
  type?: Maybe<Scalars["String"]>;
}

export interface StatItem {
  __typename?: "StatItem";
  current?: Maybe<Scalars["Float"]>;
  previous?: Maybe<Scalars["Float"]>;
}

export interface StatItemParticipant {
  __typename?: "StatItemParticipant";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
}

export interface StatMonth {
  __typename?: "StatMonth";
  start_time?: Maybe<Scalars["String"]>;
  month?: Maybe<Scalars["Int"]>;
  year?: Maybe<Scalars["Int"]>;
}

export interface StatNotificationCountItem {
  __typename?: "StatNotificationCountItem";
  totals?: Maybe<StatNotificationCountValues>;
  breakdowns?: Maybe<Array<Maybe<StatNotificationCountValues>>>;
  month_totals?: Maybe<StatNotificationCountValues>;
  month_breakdowns?: Maybe<Array<Maybe<StatNotificationCountValues>>>;
}

export interface StatNotificationCountValues {
  __typename?: "StatNotificationCountValues";
  title?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Float"]>;
  sent?: Maybe<Scalars["Float"]>;
  upcoming?: Maybe<Scalars["Float"]>;
  failed?: Maybe<Scalars["Float"]>;
}

export interface StatParticipantListItem {
  __typename?: "StatParticipantListItem";
  attendance_data_count?: Maybe<Scalars["Float"]>;
  attendance_rate?: Maybe<Scalars["Float"]>;
  date?: Maybe<Scalars["String"]>;
  event_count?: Maybe<Scalars["Float"]>;
  notification_count?: Maybe<Scalars["Float"]>;
  participant?: Maybe<StatItemParticipant>;
  rescheduled_data_count?: Maybe<Scalars["Float"]>;
}

export interface Stats {
  __typename?: "Stats";
  court_date?: Maybe<StatItem>;
  caseLoad?: Maybe<StatItem>;
  compliance?: Maybe<StatItem>;
  total?: Maybe<StatItem>;
}

export interface StatsReport {
  __typename?: "StatsReport";
  from_date?: Maybe<Scalars["String"]>;
  to_date?: Maybe<Scalars["String"]>;
  notification_counts?: Maybe<StatNotificationCountItem>;
  event_counts?: Maybe<StatEventCountItem>;
  event_list?: Maybe<Array<Maybe<StatEventListItem>>>;
  participant_list?: Maybe<Array<Maybe<StatParticipantListItem>>>;
  report_months?: Maybe<Array<Maybe<StatMonth>>>;
  report_weeks?: Maybe<Array<Maybe<StatWeek>>>;
}

export interface StatWeek {
  __typename?: "StatWeek";
  start_time?: Maybe<Scalars["String"]>;
  week?: Maybe<Scalars["Int"]>;
  year?: Maybe<Scalars["Int"]>;
}

export interface SubstanceAbuseInfo {
  __typename?: "SubstanceAbuseInfo";
  has_experimented_with_illegal_substances?: Maybe<YesNo>;
  type_of_illegal_substance_experimented?: Maybe<Scalars["String"]>;
  last_use_of_illegal_substance_date?: Maybe<Scalars["String"]>;
  has_abused_alcohol_or_marijuana?: Maybe<YesNo>;
  last_alcohol_or_marijuna_abuse_date?: Maybe<Scalars["String"]>;
  alcohol_or_marijuana_abuse_how_often?: Maybe<Scalars["String"]>;
  has_used_illegal_drugs_last_six_months?: Maybe<YesNo>;
  type_of_illegal_drug_used_last_six_months?: Maybe<Scalars["String"]>;
  how_often_illegal_drug_use_last_six_months?: Maybe<Scalars["String"]>;
  has_received_treatment_alcohol_or_marijuana?: Maybe<YesNo>;
  alcohol_or_marijuana_program_name?: Maybe<Scalars["String"]>;
  alcohol_or_marijuana_treatment_date?: Maybe<Scalars["String"]>;
}

export interface SubstanceAbuseInfoInput {
  has_experimented_with_illegal_substances?: Maybe<YesNo>;
  type_of_illegal_substance_experimented?: Maybe<Scalars["String"]>;
  last_use_of_illegal_substance_date?: Maybe<Scalars["String"]>;
  has_abused_alcohol_or_marijuana?: Maybe<YesNo>;
  last_alcohol_or_marijuna_abuse_date?: Maybe<Scalars["String"]>;
  alcohol_or_marijuana_abuse_how_often?: Maybe<Scalars["String"]>;
  has_used_illegal_drugs_last_six_months?: Maybe<YesNo>;
  type_of_illegal_drug_used_last_six_months?: Maybe<Scalars["String"]>;
  how_often_illegal_drug_use_last_six_months?: Maybe<Scalars["String"]>;
  has_received_treatment_alcohol_or_marijuana?: Maybe<YesNo>;
  alcohol_or_marijuana_program_name?: Maybe<Scalars["String"]>;
  alcohol_or_marijuana_treatment_date?: Maybe<Scalars["String"]>;
}

export enum SummaryType {
  Month = "Month"
}

export interface TaskConfig {
  __typename?: "TaskConfig";
  key: Scalars["String"];
  label: Scalars["String"];
  notifications?: Maybe<TaskNotificationConfig>;
}

export interface TaskNotificationConfig {
  __typename?: "TaskNotificationConfig";
  create?: Maybe<I18nText>;
  update?: Maybe<I18nText>;
  delete?: Maybe<I18nText>;
}

export interface TaskPayload {
  id?: Maybe<Scalars["ID"]>;
  completed_at?: Maybe<Scalars["String"]>;
  label: Scalars["String"];
  created_at?: Maybe<Scalars["String"]>;
  due_at?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  participant_id: Scalars["String"];
}

export interface TimelineEntry {
  __typename?: "TimelineEntry";
  id: Scalars["ID"];
  type: LogEntryType;
  effective_timestamp: Scalars["String"];
  timestamp: Scalars["String"];
  participant?: Maybe<Participant>;
  event?: Maybe<Event>;
  details?: Maybe<Scalars["Object"]>;
  notification?: Maybe<Notification>;
}

export enum TreatmentType {
  Residential = "residential",
  PartialHospitalization = "partial_hospitalization",
  Iop = "iop",
  Mixed = "mixed"
}

export interface TwilioConfig {
  __typename?: "TwilioConfig";
  source_number?: Maybe<Scalars["String"]>;
}

export interface UpdateObligationEvent {
  __typename?: "UpdateObligationEvent";
  event_template?: Maybe<EventTemplate>;
  result: MutationResult;
}

export enum VerificationOptions {
  UpdateOrRequest = "update_or_request",
  WithConfirmation = "with_confirmation"
}

export interface VerifiedAaMeeting {
  __typename?: "VerifiedAaMeeting";
  value?: Maybe<Scalars["String"]>;
  last_value?: Maybe<Scalars["String"]>;
  verification_obligation?: Maybe<Obligation>;
  confirmation_obligation?: Maybe<Obligation>;
  verification_obligation_id?: Maybe<Scalars["String"]>;
  confirmation_obligation_id?: Maybe<Scalars["String"]>;
}

export interface VerifiedAddress {
  __typename?: "VerifiedAddress";
  value?: Maybe<Scalars["String"]>;
  last_value?: Maybe<Scalars["String"]>;
  verification_obligation?: Maybe<Obligation>;
  confirmation_obligation?: Maybe<Obligation>;
  verification_obligation_id?: Maybe<Scalars["String"]>;
  confirmation_obligation_id?: Maybe<Scalars["String"]>;
}

export interface VerifiedEmployment {
  __typename?: "VerifiedEmployment";
  value?: Maybe<Scalars["String"]>;
  last_value?: Maybe<Scalars["String"]>;
  verification_obligation?: Maybe<Obligation>;
  confirmation_obligation?: Maybe<Obligation>;
  verification_obligation_id?: Maybe<Scalars["String"]>;
  confirmation_obligation_id?: Maybe<Scalars["String"]>;
}

export interface VerifiedLawEnforcementContact {
  __typename?: "VerifiedLawEnforcementContact";
  value?: Maybe<Scalars["String"]>;
  last_value?: Maybe<Scalars["String"]>;
  confirmation_obligation?: Maybe<Obligation>;
  confirmation_obligation_id?: Maybe<Scalars["String"]>;
}

export interface VictimInfo {
  __typename?: "VictimInfo";
  was_victim_in_case?: Maybe<YesNo>;
  has_protective_order?: Maybe<YesNo>;
  has_stay_away_order?: Maybe<YesNo>;
  has_peaceful_contact_order?: Maybe<YesNo>;
  protective_order_notes?: Maybe<Scalars["String"]>;
  victims?: Maybe<Array<ParticipantContact>>;
}

export interface VictimInfoInput {
  was_victim_in_case?: Maybe<YesNo>;
  has_protective_order?: Maybe<YesNo>;
  has_stay_away_order?: Maybe<YesNo>;
  has_peaceful_contact_order?: Maybe<YesNo>;
  protective_order_notes?: Maybe<Scalars["String"]>;
  victims?: Maybe<Array<Maybe<ParticipantContactInput>>>;
}

export interface VictimName {
  __typename?: "VictimName";
  first?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["String"]>;
}

export interface VictimNameInput {
  first?: Maybe<Scalars["String"]>;
  last?: Maybe<Scalars["String"]>;
}

export enum YesNo {
  Yes = "yes",
  No = "no"
}

export enum YesNoUnknown {
  Yes = "yes",
  No = "no",
  Unknown = "unknown"
}

export interface ZenDesk {
  __typename?: "ZenDesk";
  token?: Maybe<Scalars["String"]>;
  expires_at?: Maybe<Scalars["String"]>;
}

export enum ZeroToThreeOrMore {
  Zero = "zero",
  One = "one",
  Two = "two",
  Three = "three",
  More = "more"
}
